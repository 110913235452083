<template>
    <div>
        <header-components activeClass="about"></header-components>
        <header-components-mobile></header-components-mobile>
        <div class="section-header-block section-header-block--2 section-header-block-white mb-4">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center mb-1">
                            <div class="d-block text-sm font-600 text-primary">Learning Management System</div>
                            <h1 class="text-mega font-600">Solusi Orang Tua</h1>
                        </div>
                        <div class="col-md-10">
                            <img alt="parent-solution-image" src="/images/assets-img-parent.png" class="w-100">
                        </div>
                        <div class="col-md-9 text-center">
                            <div class="text-lg d-block pt-5">Scola memberikan kemudahan bagi orang tua dalam monitori perkembangan, aktivitas, dan jadwal belajar anak.</div>
                            <div class="d-flex align-items-center justify-content-center pt-4">
                                  <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary btn-lg rounded-pill mr-2">Daftar Sekarang</a>
                                <a href="https://form.jotform.com/222553276834460" target="_blank" class="btn btn-white btn-lg rounded-pill font-italic">Request Free Trial</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-w-bg-block section-w-bg-block-primary-dark text-white">
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap py-lg-5">
                    <div class="row justify-content-center">
                        <div class="col-md-10">
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="parent-solution-image" src="/images/solusi-orangtua-icon/solusi-orangtua-icon-1.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Informasi Keuangan</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                        Menginformasikan pembayaran uang sekolah, SPP, dan informasi terkait keuangan lainnya hanya dalam satu aplikasi.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="parent-solution-image" src="/images/solusi-orangtua-icon/solusi-orangtua-icon-2.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Akses Jadwal Belajar</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                        Memantau jadwal anak dan modul pembelajaran yang diajarkan oleh guru kepada siswa.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="parent-solution-image" src="/images/solusi-orangtua-icon/solusi-orangtua-icon-3.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Pantau Rapor Anak</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                        Memantau progres pembelajaran dengan fitur real time update.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="parent-solution-image" src="/images/solusi-orangtua-icon/solusi-orangtua-icon-4.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Pengumuman Informasi Sekolah </div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Menginformasikan agenda akademik, seperti jadwal ujian, seminar, keagamaan, dan agenda sosial yang dilaksanakan. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-lg">
             <div class="container-fluid container-fluid-gap">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card h-100 bg-light shadow-none">
                            <div class="card-body h-100 text-center">
                                <img alt="parent-solution-image" src="/images/assets-img-school-2.png" class="w-100 rounded-lg mb-4" />
                                <div class="d-block font-500 text-lg mb-3">
                                    Solusi Sekolah
                                </div>
                                <div class="d-block mb-5">
                                    <p class="m-0">
                                        Scola menciptakan ruang pembelajaran digital untuk menghubungkan kegiatan belajar yang efektif.
                                    </p>
                                </div>
                                <router-link to="/school-solution-lms" class="btn btn-primary rounded-pill">Lihat Solusi</router-link>
                            </div>
                        </div>
                    </div>
                     <div class="col-md-6">
                        <div class="card h-100 bg-light shadow-none">
                            <div class="card-body h-100 text-center">
                                <img alt="parent-solution-image" src="/images/assets-img-teacher-2.png" class="w-100 rounded-lg mb-4" />
                                <div class="d-block font-500 text-lg mb-3">
                                    Solusi Guru
                                </div>
                                <div class="d-block mb-5">
                                    <p class="m-0">
                                        Scola membantu guru dalam mencapai potensi maksimal dalam mengajar sehingga menciptakan lingkup pendidikan terbaik.
                                    </p>
                                </div>
                                <router-link to="/teacher-solution-lms" class="btn btn-primary rounded-pill">Lihat Solusi</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Help CTA -->
        <help-cta-components></help-cta-components>
        <!-- Footer -->
        <footer-components></footer-components>
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Footer from '@/components/Footer.vue'
    import lozad from 'lozad';

    export default {
        name: 'Solusi Orang Tua LMS',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
            'footer-components': Footer
        },
        metaInfo: {
            title: 'Produk Scola - Learning Management System',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>