<template>

    <div class="security-disclosure">

        <header-components activeClass="securityDisclosure"></header-components>
        <header-components-mobile></header-components-mobile>
        <!-- Security Disclosure -->
        <div class="section push-top">
            <div class="container">
                <div class="section">
                  <div class="row justify-content-center">
                    <div class="col-md-8">
                      <h1 class="text-xxlg">Security Disclosure</h1>
                      <p>Our team works vigilantly to keep customer information secure. We recognize the important role that security researchers and our community play in keeping Scola and our customers secure. If you discover a product or website vulnerability, please notify us using the guidelines below.</p>
                    </div>
                  </div>
                </div>
            </div>
        </div>

        <!-- Report Vulnerability -->
        <div class="container">
          <div class="row">
            <div class="col-md-6 mb-md-0 mb-4">
              <div class="d-block bg-lighter p-md-5 p-4 h-100 rounded border">
                <h2 class="text-lg pr-md-4 pr-0 d-block">Report a vulnerability specific to a Scola product</h2>
                <ul>
                  <li>Scola Company Website</li>
                  <li>Scola Learning Management System</li>
                  <li>Scola Mobile for Student</li>
                  <li>Scola Mobile for Parent</li>
                </ul>
                <br>
                <a href="mailto:security@scola.id?subject=[Security%20Disclosure]" class="text-md font-weight-bolder">Submit Scola Product Vulnerability</a>
              </div>
            </div>
            <div class="col-md-6 mb-md-0 mb-4">
              <div class="d-block bg-lighter p-md-5 p-4 h-100 rounded border">
                  <h2 class="text-lg pr-md-4 pr-0 d-block">Report any other (non-product) vulnerability involving Scola</h2>
                  <ul>
                    <li>Custom Solution with Scola LMS</li>
                  </ul>
                  <br>
                  <a href="mailto:security@scola.id?subject=[Security%20Disclosure]" class="text-md font-weight-bolder">Submit Non-Product Related Vulnerability</a>
              </div>
            </div>
          </div>
        </div>

        <!-- Security Researcher -->
        <div class="section">
          <div class="container">
            <div class="section">
              <div class="row justify-content-center">
                <div class="col-md-8">
                  <h1 class="text-xxlg">Guidelines for Responsible Disclosure Program</h1>
                  <p>Scola follows the principle of Coordinated Vulnerability Disclosure. When Scola receives a security vulnerability report, we work as quickly as possible to develop an update and release it to our customers, so they can be protected.</p>
                  <p>We ask the security community to give us an opportunity to fix vulnerabilities before releasing information publicly and to follow the guidelines below:</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Do & Dont -->
        <div class="container">
          <div class="row">
            <div class="col-md-6 mb-md-0 mb-4">
              <div class="d-block bg-lighter p-md-5 p-4 h-100 rounded border">
                <h2 class="text-lg pr-md-4 pr-0 d-block">Please do</h2>
                <ul>
                  <li class="mb-2">
                    Share the security issue with us before making it public on social media, message boards, mailing lists, conference talks, and other forums.
                  </li>
                  <li class="mb-2">
                    Provide full details of the security issue including steps to reproduce and the details of the system where the tests were conducted.
                  </li>
                  <li class="mb-2">
                    Wait until notified that the vulnerability has been resolved before disclosing it to others. We take the security of our customers very seriously, however some vulnerabilities take longer than others to resolve. There are several teams involved in working on these vulnerabilities depending on the vulnerability and the function being exploited.
                  </li>
                  <li>
                    If you are planning to present at a conference, let us know the date as soon as possible.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 mb-md-0 mb-4">
              <div class="d-block bg-lighter p-md-5 p-4 h-100 rounded border">
                  <h2 class="text-lg pr-md-4 pr-0 d-block">Please do not</h2>
                  <ul>
                    <li class="mb-2">
                      Cause potential or actual damage to Scola users, systems or applications.
                    </li>
                    <li class="mb-2">
                      Use an exploit to view unauthorized data or corrupt data.
                    </li>
                    <li class="mb-2">
                      Request compensation for the reporting of security issues either to Scola, or through any external marketplace for vulnerabilities, whether black-market or otherwise.
                    </li>
                    <li class="mb-2">
                      Engage in disruptive testing like DoS or any action that could impact the confidentiality, integrity or availability of information and systems.
                    </li>
                    <li class="mb-2">
                      Engage in social engineering or phishing of customers or employees.
                    </li>
                    <li>
                      Request for compensation for time and materials or vulnerabilities discovered, we will consider compensation on a case-by-case basis.
                    </li>
                  </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <div class="container">
            <div class="section">
              <div class="row justify-content-center">
                <div class="col-md-8">
                  <h1 class="text-xxlg mb-2">Responsible Disclosure Policy</h1>
                  <h2>(applies to Non-Product Vulnerability Disclosures)</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-4 mb-md-0 mb-4">
              <div class="d-block bg-lighter p-md-5 p-4 h-100 rounded border">
                <h2 class="text-lg pr-md-4 pr-0 d-block">Accepted Web Vulnerabilities:</h2>
                <ul>
                  <li class="mb-2">OWASP Top 10 vulnerability categories</li>
                  <li>Other vulnerabilities with demonstrated impact</li>
                </ul>
              </div>
            </div>
            <div class="col-md-4 mb-md-0 mb-4">
              <div class="d-block bg-lighter p-md-5 p-4 h-100 rounded border">
                <h2 class="text-lg pr-md-4 pr-0 d-block">Out of Scope Web Vulnerabilities:</h2>
                <ul>
                  <li class="mb-2">Theoretical vulnerabilities</li>
                  <li class="mb-2">Informational disclosure of non-sensitive data</li>
                  <li class="mb-2">Low impact and/or Low Likelihood of occurrence session management issues</li>
                  <li>Self XSS (user defined payload)</li>
                </ul>
              </div>
            </div>
            <div class="col-md-4 mb-md-0 mb-4">
              <div class="d-block bg-lighter p-md-5 p-4 h-100 rounded border">
                <h2 class="text-lg pr-md-4 pr-0 d-block">Rules of Engagement</h2>
                <ul>
                  <li class="mb-2">No Denial of Service testing</li>
                  <li class="mb-2">No Physical or Social Engineering</li>
                  <li class="mb-2">No testing of Third-party Services</li>
                  <li class="mb-2">No uploading of any vulnerability or client-related content to third-party utilities (e.g. Github, DropBox, YouTube)</li>
                  <li class="mb-2">All attack payload data must use professional language</li>
                  <li class="mb-2">If able to gain access to a system, accounts, users, or user data, stop at the point of recognition and report. Do attempt to determine how much more is accessible.</li>
                  <li>Low Impact Vulnerabilities are out of scope (see details below)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <div class="container">
            <div class="section">
              <div class="row justify-content-center">
                <div class="col-md-8">
                  <h1 class="text-lg mb-2">Responsible Disclosure Details for Web Vulnerabilities</h1>
                  <p>The following vulnerabilities are considered a very low impact and hence are out of scope for our Responsible Disclosure program.</p>
                  <ul>
                    <li class="mb-2">
                      Account/e-mail enumeration using brute-force attacks
                      <ul>
                        <li>Valid user account/email enumeration not requiring brute-force will be considered</li>
                      </ul>
                    </li>
                    <li class="mb-2">Any low impact issues related to session management (i.e. concurrent sessions, session expiration, password reset/change log out, etc.)</li>
                    <li class="mb-2">Bypassing content restrictions in uploading a file without proving the file was received</li>
                    <li class="mb-2">Clickjacking/UI redressing</li>
                    <li class="mb-2">Client-side application/browser autocomplete or saved password/credentials</li>
                    <li class="mb-2">Descriptive or verbose error pages without proof of exploitability or obtaining sensitive information</li>
                    <li class="mb-2">Directory structure enumeration (unless the fact reveals exceptionally useful information)</li>
                    <li class="mb-2">Incomplete or missing SPF/DMARC/DKIM records</li>
                    <li class="mb-2">Issues related to password/credential strength, length, lockouts, or lack of brute-force/rate limiting protections
                      <ul>
                        <li>Account compromises (especially admin) as a result of these issues will likely be considered VALID</li>
                      </ul>
                    </li>
                    <li class="mb-2">Lack of SSL or Mixed content
                      <ul>
                        <li>Leaking Session Cookies, User Credentials, or other sensitive data will be reviewed on a case by case basis</li>
                        <li>If leaking of sensitive data requires MiTM positioning to exploit, it will be considered out of scope</li>
                      </ul>
                    </li>
                    <li class="mb-2">Login/Logout/Unauthenticated/Low-impact CSRF
                      <ul>
                        <li>CSRF Vulnerabilities may be acceptable if they are of higher impact. Examples of low impact CSRF include: Add/Delete from Cart, Add/remove wishlist/favorites, Nonsevere preference options, etc.</li>
                      </ul>
                    </li>
                    <li class="mb-2">Low impact Information disclosures (including Software version disclosure)</li>
                    <li class="mb-2">Missing Cookie flags</li>
                    <li class="mb-2">Missing/Enabled HTTP Headers/Methods which do not lead directly to a security vulnerability</li>
                    <li class="mb-2">Reflected file download attacks (RFD)</li>
                    <li class="mb-2">Self-exploitation (i.e. password reset links or cookie reuse)</li>
                    <li class="mb-2">SSL/TLS best practices that do not contain a fully functional proof of concept</li>
                    <li class="mb-2">URL/Open Redirection</li>
                    <li class="mb-2">Use of a known-vulnerable library which leads to a low-impact vulnerability (i.e. jQuery outdated version leads to low impact XSS)</li>
                    <li class="mb-2">Valid bugs or best practice issues that are not directly related to the security posture of the client</li>
                    <li class="mb-2">Vulnerabilities affecting users of outdated browsers, plugins or platforms</li>
                    <li class="mb-2">Vulnerabilities that allow for the injection of arbitrary text without allowing for hyperlinks, HTML, or JavaScript code to be injected</li>
                    <li>Vulnerabilities that require the user/victim to perform extremely unlikely actions (i.e. Self-XSS)
                      <ul>
                        <li>Self-XSS for a Persistent/Stored XSS will be considered. Please review the Self-XSS article for more information.</li>
                        <li>Any type of XSS that requires a victim to press an unlikely key combination is NOT in scope (i.e. alt+shift+x for payload execution)</li>
                      </ul>
                    </li>
                  </ul>
                  <br>
                  <p>Additional specific vulnerability types considered out of scope due to low impact:</p>
                  <ul>
                    <li class="mb-2">IIS Tilde File and Directory Disclosure</li>
                    <li class="mb-2">SSH Username Enumeration</li>
                    <li class="mb-2">Wordpress Username Enumeration</li>
                    <li class="mb-2">SSL Weak Ciphers/ POODLE</li>
                    <li class="mb-2">CSV Injection</li>
                    <li class="mb-2">PHP Info</li>
                    <li class="mb-2">Server-Status if it does not reveal sensitive information</li>
                    <li>Snoop Info Disclosures</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer-components></footer-components>

    </div>

</template>

<script>
  import Header from '@/components/Header.vue'
  import HeaderMobile from '@/components/HeaderMobile.vue'
  import Footer from '@/components/Footer.vue'
  import lozad from 'lozad'

  export default {
    name: 'SecurityDisclosure',
    components: {
      'header-components': Header,
      'header-components-mobile': HeaderMobile,
      'footer-components': Footer
    },
    metaInfo: {
      title: 'Security Disclosure - Scola LMS',
      titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
    },
    mounted() {
      const observer = lozad();
      observer.observe();
    }
  }
</script>