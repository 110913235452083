<template>
    <div>
        <header-components activeClass="about"></header-components>
        <header-components-mobile></header-components-mobile>
        <div class="section-header-block section-header-block--2 mb-4">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-4">
                            <div class="card-content-group">
                                <div class="card-content-img shadow-sm">
                                    <div class="card-content-img-inner" style="background-image:url('/images/edtiva-logo.png');"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="d-block pl-lg-5">
                                <div class="d-block">
                                    <div class="d-block mb-4">
                                        <img src="/images/product-logo/Scola_Apps_maincolor.svg" height="30" alt="scola-image">
                                    </div>
                                    <h1 class="text-mega font-600">Edtiva</h1>
                                </div>
                                <div class="text-lg d-block">Platform terbaik yang hadir untuk pembelajaran online dengan fitur mudah, lengkap dan dapat disesuaikan dengan kebutuhan perusahaan atau lembaga pendidikan.</div>
                                <div class="d-block text-md pt-4">
                                    <ul>
                                        <li>Mudah Digunakan</li>
                                        <li>Biaya Terjangkau</li>
                                        <li>Fitur LMS Lengkap</li>
                                        <li>Tersedia Aplikasi & Website</li>
                                        <li>Penyaluran SDM</li>
                                    </ul>
                                </div>
                                <div class="d-flex align-items-center pt-4">
                                    <a href="https://edtiva.id" target="_blank" class="btn btn-white btn-lg rounded-pill mr-2">Lihat Website<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-lg pt-0">
                <div class="container-fluid container-fluid-gap">
                    <div class="row">
                        <div class="col-md-12">
                            <img src="/images/edtiva-preview-app.png" class="w-100" alt="edtiva-image">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Scola App -->
        <div class="section-w-bg-block section-w-bg-block-primary-dark">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="d-block mb-4">
                                <img src="/images/product-logo/Scola_Apps_white.svg" height="37" alt="scola-image">
                            </div>
                            <div class="d-block mb-5">
                                <div class="h2 text-xxlg font-600 text-white">Dukungan aplikasi untuk membantu proses pembelajaran digital di sekolah.</div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-sm">
                        <div class="col-md-3">
                            <router-link to="/app-vibook" class="card bg-white m-0">
                                <div class="d-block p-3">
                                    <img src="/images/vibook-logo.jpg" class="w-100 rounded" alt="vibook-logo-image">
                                </div>
                            </router-link>
                            <div class="d-block font-500 text-md mt-3 text-white">
                                Vibook - Perpustakaan Digital
                            </div>
                        </div>
                        <div class="col-md-3">
                            <router-link to="/app-tutooria" class="card bg-white m-0">
                                <div class="d-block p-3">
                                    <img src="/images/tutooria-logo.png" class="w-100 rounded" alt="tutooria-logo-image">
                                </div>
                            </router-link>
                            <div class="d-block font-500 text-md mt-3 text-white">
                                Tutooria - Platform Belajar Bersama Tutor
                            </div>
                        </div>
                        <div class="col-md-3">
                            <router-link to="/app-virtualin" class="card bg-white m-0">
                                <div class="d-block p-3">
                                    <img src="/images/virtualin-logo.png" class="w-100 rounded" alt="virtualin-logo-image">
                                </div>
                            </router-link>
                            <div class="d-block font-500 text-md mt-3 text-white">
                                Virtualin - Rancang Pameran Online Sekolah
                            </div>
                        </div>
                        <div class="col-md-3">
                            <router-link to="/app-edtiva" class="card bg-white m-0">
                                <div class="d-block p-3">
                                    <img src="/images/edtiva-logo.png" class="w-100 rounded" alt="edtiva-logo-image">
                                </div>
                            </router-link>
                            <div class="d-block font-500 text-md mt-3 text-white">
                                Edtiva - Platform Pembelajaran Online
                            </div>
                        </div>
                    </div>
                    <div class="d-block small text-white pt-5">Scola Apps powered by Scola</div>
                </div>
            </div>
        </div>

        <help-cta-components></help-cta-components>
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import lozad from 'lozad';

    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faArrowRight} from '@fortawesome/free-solid-svg-icons';

    library.add(faArrowRight)

    export default {
        name: 'Aplikasi - Edtiva',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
        },
        metaInfo: {
            title: 'Produk Scola - Learning Management System',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>