<template>
    <div>
        <header-components activeClass="about"></header-components>
        <header-components-mobile></header-components-mobile>
        <div class="section-header-block section-header-block--2 mb-4">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center mb-1">
                            <!-- <div class="d-block font-600 text-primary">Produk Scola</div> -->
                            <!-- <div class="d-block mb-3">
                                <img alt="product-si-image" src="/images/product-logo/Scola_School_Pro_maincolor.svg" height="40">
                            </div> -->
                            <div class="d-flex align-items-center justify-content-center mb-4" style="margin-left:-8px;">
                                <img alt="product-si-image" src="/images/icon-product-2.webp" width="80">
                                <div class="d-block font-600 text-lg ml-2">SCHOOL PRO</div>
                            </div>
                            <h1 class="text-mega font-600">Kelola data sekolah dengan teratur & terpusat.</h1>
                            <div class="d-block text-sm text-muted">Powered by Scola</div>
                        </div>
                        <div class="col-md-7 py-5">
                            <img alt="product-si-image" src="/images/sil-img-2.webp" class="w-100">
                        </div>
                        <div class="col-md-10 text-center">
                            
                            <div class="d-flex align-items-center justify-content-center pt-4">
                                  <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary btn-lg rounded-pill mr-2">Daftar Sekarang</a>
                                <a href="https://form.jotform.com/222553276834460" target="_blank" class="btn btn-white btn-lg rounded-pill font-italic">Request Free Trial</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-lg pt-5">
            <div class="container-fluid container-fluid-gap">
                <div class="row row-sm">
                    <div class="col-md-12 mb-4">
                        <div class="card h-100 bg-primary text-white rounded shadow-none rounded-lg">
                            <div class="card-body">
                                <div class="d-flex align-items-start d-flex-mobile p-lg-5 p-md-4">
                                    <div class="flex-shrink-0 mr-md-5 mr-3 mb-md-0 mb-4">
                                        <img alt="product-si-image" src="/images/monitoring2.png" width="240">
                                    </div>
                                    <div class="d-block">
                                        <div class="h2 text-xlg font-600">Pengelolaan data terbaik.</div>
                                        <div class="text-lg d-block pt-md-2">Solusi untuk digitalisasi proses akademik, kegiatan, dan kelola semua data di sekolah serta lembaga pendidikan menjadi lebih rapi, terpusat, dan dapat dimonitoring dengan baik serta pendukung sekolah dalam pengambilan keputusan. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div class="row row-sm">
                    <div class="col-md-3 col-6 mb-4">
                        <router-link to="/si-ppdb" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/sc-ppdb.png');"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">PPDB</div>
                                <div class="d-block text-sm">Pengelolaan penerimaan siswa baru di sekolah.</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 col-6 mb-4">
                        <router-link to="/si-repository" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/sc-respositori.png');"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Respositori</div>
                                <div class="d-block text-sm">Mengelola dokumen administrasi arsip digital pada sekolah.</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 col-6 mb-4">
                        <router-link to="/si-sarpras" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/sc-sarpras.png');"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Sarana & Prasarana</div>
                                <div class="d-block text-sm">Sistem informasi untuk mengelola & mencatat sarana prasarana.</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 col-6 mb-4">
                        <router-link to="/si-pendidik" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/sc-tendik.png');"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Tendik</div>
                                <div class="d-block text-sm">Manajemen proses penyimpanan data tenaga didik.</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 col-6 mb-4">
                        <router-link to="/si-keuangan" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/sc-keuangan.png');"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Keuangan</div>
                                <div class="d-block text-sm">Menginformasikan pembayaran uang sekolah, SPP, dan informasi terkait keuangan lainnya hanya dalam satu aplikasi.</div>
                            </div>
                        </router-link>
                    </div>
                     <div class="col-md-3 col-6 mb-4">
                        <router-link to="/si-rapor" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/sc-raport.png');"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">E-Rapor</div>
                                <div class="d-block text-sm">Penyimpanan data nilai digital yang mencakup nilai tugas, ujian, dan sikap dalam format excel untuk memudahkan guru, siswa, dan orang tua dalam memantau progres pembelajaran.</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 col-6 mb-4">
                        <router-link to="/si-portal" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/sc-portal.png');"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Portal Pendidikan</div>
                                <div class="d-block text-sm">Halaman untuk sekolah dapat mengatur semua layanan yang akan di tampilkan ke siswa, guru dan orang tua sehingga mudah di akses.</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3 col-6 mb-4">
                        <router-link to="/product-si" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/sc-alumni.png');"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Alumni</div>
                                <div class="d-block text-sm">Sekolah dapat memonitoring, kelola dan tracking data alumni dengan mudah, rapi dan terpusat.</div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-w-bg-block section-w-bg-block-primary-dark">
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="d-block mb-5 pb-5 text-white">
                        <div class="h2 text-xxlg font-600">Mengapa School Pro?</div>
                        <div class="d-block">
                            <p class="m-0 text-lg">Dengan sistem manajemen terstruktur, School Pro memudahkan pengelolaan sistem sekolah dengan produk-produk terbaik.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-5">
                            <div class="card shadow-none bg-transparent text-white h-100 m-0">
                                <div class="d-flex flex-column h-100 border-bottom pb-4">
                                    <div class="flex-1">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-2x text-primary"/>
                                        <div class="d-block text-lg font-500">Produk saling terintegrasi.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-5">
                            <div class="card shadow-none bg-transparent text-white h-100 m-0">
                                <div class="d-flex flex-column h-100 border-bottom pb-4">
                                    <div class="flex-1">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-2x text-primary"/>
                                        <div class="d-block text-lg font-500">Kemudahan kelola <span class="font-italic">user</span> pengguna.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-5">
                            <div class="card shadow-none bg-transparent text-white h-100 m-0">
                                <div class="d-flex flex-column h-100 border-bottom pb-4">
                                    <div class="flex-1">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-2x text-primary"/>
                                        <div class="d-block text-lg font-500">Kemudahan dalam monitoring dan pengawasan.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-5">
                            <div class="card shadow-none bg-transparent text-white h-100 m-0">
                                <div class="d-flex flex-column h-100 border-bottom pb-4">
                                    <div class="flex-1">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-2x text-primary"/>
                                        <div class="d-block text-lg font-500">Kumpulan data terpusat lingkup sekolah.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-5">
                            <div class="card shadow-none bg-transparent text-white h-100 m-0">
                                <div class="d-flex flex-column h-100 border-bottom pb-4">
                                    <div class="flex-1">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-2x text-primary"/>
                                        <div class="d-block text-lg font-500">Kemudahan fitur melaporkan kejadian yang terjadi sehingga mempercepat pengambilan keputusan.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-5">
                            <img alt="product-si-image" src="/images/assets-doughnut-chart.png" class="w-100">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Help CTA -->
        <help-cta-components></help-cta-components>
        <!-- Footer -->
        <footer-components></footer-components>
        
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Footer from '@/components/Footer.vue'
    import lozad from 'lozad';

    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faCircleCheck, faUserShield, faPuzzlePiece, faShieldHalved, faIdCardClip, faArrowRight } from '@fortawesome/free-solid-svg-icons';

    library.add(faCircleCheck, faUserShield, faPuzzlePiece, faShieldHalved, faIdCardClip, faArrowRight )

    export default {
        name: 'Produk SI',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
            'footer-components': Footer
        },
        metaInfo: {
            title: 'Produk Scola - Learning Management System',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>