<template>
    <div>
        <div class="section section-lg bg-lighter vh-100">
            <div class="container">
                <div class="row">
                    <div class="col-md-7">
                        <div class="d-block">
                            <a href="/"><img src="images/Scola_Logo_Lockup_Blue_NEW.svg" alt="Scola Logo"
                                    width="120"></a>
                            <div class="d-block mt-4 mb-5">
                                <h1 class="title-xl mb-4 font-weight-bolder">Pendaftaran berhasil!</h1>
                                <p class="font-weight-normal">Terima kasih <span class="font-weight-bolder">{{title}}.
                                        {{fullname}} </span> telah melakukan pendaftaran di Scola. Tim kami akan
                                    segera memprosesnya dan akan mengubungi anda melalui email atau telepon/WhatsApp.
                                </p>
                                <div class="card shadow-sm">
                                    <div class="card-body">
                                        <p class="text-sm mb-1">Langkah selanjutnya:</p>
                                        <p class="font-weight-normal text-sm">Setelah formulir Anda terverifikasi,
                                            sekolah Anda berhak mendapatkan <span class="font-weight-bolder">gratis masa coba selama 30 hari</span>.</p>
                                        <div class="d-inlin-block mt-3 text-xs text-dark text-muted">Salam hangat dari
                                            Tim Scola</div>
                                    </div>
                                </div>
                                <p class="font-weight-normal text-xs"><span class="fe fe-calendar"></span> Pendaftaran
                                    dikirim tanggal: {{currentDate}}</p>
                            </div>
                            <div class="d-flex">
                                <a href="/" class="btn btn-primary btn-primary-3d btn-push mr-3">Kembali ke halaman
                                    utama</a>
                                <a href="/#help" class="btn btn-link mr-3">Bantuan</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'register-success',
        metaInfo: {
            title: 'Register Berhasil',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        data() {
            return {
                title: localStorage.getItem('title'),
                fullname: localStorage.getItem('fullname'),
                currentDate: localStorage.getItem('currentDate'),
            }
        }
    }
</script>