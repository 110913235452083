<template>
    <div class="content">
        <header-components activeClass="content"></header-components>
        <header-components-mobile></header-components-mobile>
        <!-- Materi Belajar -->
        <div class="section section-content push-top">
            <div class="content-list-menu">
                <div class="clm-item active">Konten untuk SMA</div>
                <div class="clm-item">Konten untuk SMP</div>
                <div class="clm-item">Konten untuk SD</div>
                <div class="clm-item">Konten untuk TK</div>
            </div>
            <div class="content-media">
                <div class="row mb-4">
                    <div class="col-md-6">
                        <select class="input-form custom-select">
                            <option>Lihat Semua Materi</option>
                            <option>Video Materi</option>
                            <option>Audio Materi</option>
                            <option>Teks</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <select class="input-form custom-select">
                            <option>Lihat Semua Kelas</option>
                            <option>Kelas XII</option>
                            <option>Kelas XI</option>
                            <option>Kelas X</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9">
                        <div class="d-block mb-5">
                            <div class="text-left">
                                <div class="text-sm mb-2">Materi belajar tingkat SMA</div>
                                <div class="title-md font-weight-bolder mb-4">Kami menyediakan ratusan konten pembelajaran untuk siswa dan guru SMA</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/1.%20Dasar-Dasar%20Vektor-1.m4v" data-lity class="card shadow-sm border lift">
                            <div class="p-3">
                                <div class="col-img" style="background-image:url('images/img-materi-1.jpg');">
                                </div>
                                <div class="d-block mt-3 text-left">
                                    <span class="font-weight-bolder">Cara Membuat Magnet | IPA Terpadu</span><br>
                                    <span class="text-sm">Kelas 12</span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-6 mb-3">
                        <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/1_c3fdbe65252ca7fa1797f87b2c220df5fa3803c74671448d3e8926634efa30ba20200722043219.mp4" data-lity class="card shadow-sm border lift">
                            <div class="p-3">
                                <div class="col-img" style="background-image:url('images/img-materi-2.jpg');">
                                </div>
                                <div class="d-block mt-3 text-left">
                                    <span class="font-weight-bolder">Bagaimana Cara Kerja Pernapasan | IPA Terpadu</span><br>
                                    <span class="text-sm">Kelas 12</span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-6 mb-3 mb-lg-5">
                        <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/1_bcd50c5c1dd0b3cd5ff0d60114c8d9fcec382c942641ac481f8e1d537278832520200811021337.pdf" data-lity class="card shadow-sm border lift">
                            <div class="p-3">
                                <div class="col-img" style="background-image:url('images/img-materi-3.webp');">
                                </div>
                                <div class="d-block mt-3 text-center font-weight-bolder">
                                    Materi PDF
                                </div>
                            </div>
                        </a>
                        <div class="d-flex flex-wrap mb-4 center-md">
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/1_dddb96142e6426733b1354b25b220b7df8fa51f09d1daa086fd877a87b5d367720200716030053.pdf" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Bahasa Indonesia</div>
                            </a>
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/fcd063126c23deed8f3616e9e47c14d66b204422dee1f8d091839c1cc0a2607420191021031052.pdf" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Bahasa Inggris</div>
                            </a>
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/1_bcd50c5c1dd0b3cd5ff0d60114c8d9fcec382c942641ac481f8e1d537278832520200811021337.pdf" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Matematika</div>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3 mb-lg-5">
                        <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_soal/pembahasan/18012019102059HidrolisisAsamLemahBasaLemah_Easy_No1.mp4" data-lity class="card shadow-sm border lift">
                            <div class="p-3">
                                <div class="col-img" style="background-image:url('images/img-materi-4.jpg');">
                                </div>
                                <div class="d-block mt-3 text-center font-weight-bolder">
                                    Video Pembahasan
                                </div>
                            </div>
                        </a>
                        <div class="d-flex flex-wrap mb-4 center-md">
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_soal/pembahasan/14012019132831kamera%20-%203.mp4" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Fisika</div>
                            </a>
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_soal/pembahasan/18012019102059HidrolisisAsamLemahBasaLemah_Easy_No1.mp4" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Kimia</div>
                            </a>
                            <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_soal/pembahasan/17012019101608KLASIFIKASI%2001.mp4" data-lity>
                                <div class="btn btn-sm btn-white mr-2 btn-off mb-2">Biologi</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cta-components></cta-components>
        <help-cta-components></help-cta-components>
        <footer-components></footer-components>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import Footer from '@/components/Footer.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Cta from '@/components/Cta.vue'
    import lozad from 'lozad'

    export default {
        name: 'content',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'footer-components': Footer,
            'help-cta-components': HelpCta,
            'cta-components': Cta
        },
        metaInfo: {
            title: 'Konten',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
        }
    }
</script>