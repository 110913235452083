<template>
    <div>
        <header-components activeClass="about"></header-components>
        <header-components-mobile></header-components-mobile>
        <div class="section-header-block section-header-block--2 mb-4">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center mb-1">
                            <!-- <div class="d-block text-sm font-600 text-primary">Sistem Informasi</div> -->
                            <div class="d-block mb-3">
                                <img alt="si-keuangan-image" src="/images/product-logo/Scola_School_Pro_maincolor.svg" height="36">
                            </div>
                            <h1 class="text-mega font-600">Portal Pendidikan</h1>
                        </div>
                        <div class="col-md-9 text-center">
                            <div class="text-lg d-block pt-5">Halaman untuk sekolah dapat mengatur semua layanan yang akan di tampilkan ke siswa, guru, dan orang tua sehingga mudah di akses
                                .</div>
                            <div class="d-flex align-items-center justify-content-center pt-4">
                                  <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary btn-lg rounded-pill mr-2">Daftar Sekarang</a>
                                <!-- <a href="https://form.jotform.com/222553276834460" target="_blank" class="btn btn-white btn-lg rounded-pill">Request Free Trial</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-lg pt-0">
            <div class="container-fluid container-fluid-gap">
                <div class="row">
                    <div class="col-md-12">
                        <img alt="si-keuangan-image" src="/images/sim-portal.png" class="w-100">
                    </div>
                </div>
            </div>
        </div>
        <div class="section-w-bg-block section-w-bg-block-primary-dark text-white">
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap py-lg-5">
                    <div class="row justify-content-center">
                        <div class="col-md-10">
                            <div class="d-block">
                                <div class="d-block text-lg mb-2 font-600">Kelola Portal Pendidikan</div>
                                <div class="d-block">
                                    <p class="m-0 text-md">
                                       Kelola layanan yang di tampilkan ke siswa, guru, dan orang tua.
                                    </p>
                                </div>
                            </div>
                        </div>
        
                    </div>
                </div>
            </div>
            
        </div>

        <!-- Program -->
        <!-- <div class="section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="d-block mb-5">
                    <div class="h2 text-mega font-600">Program Kami Lainnya</div>
                </div>
                <div class="row row-sm">
                    <div class="col-md-4 col-6 mb-4">
                        <router-link to="/si-repository" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Repository</div>
                                <div class="d-block text-sm">Mengelola dokumen administrasi arsip digital pada sekolah.</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-4 col-6 mb-4">
                        <router-link to="/si-ppdb" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">PPDB</div>
                                <div class="d-block text-sm">Pengelolaan penerimaan siswa baru di sekolah.</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-4 col-6 mb-4">
                        <router-link to="/si-sarpras" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Sarana Prasarana</div>
                                <div class="d-block text-sm">Sistem informasi untuk mengelola & mencatat sarana prasarana.</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-4 col-6 mb-4">
                        <router-link to="/si-pendidik" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Tenaga Pendidik</div>
                                <div class="d-block text-sm">Manajemen proses penyimpanan data tenaga didik.</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-4 col-6 mb-4">
                        <router-link to="/si-keuangan" class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Keuangan</div>
                                <div class="d-block text-sm">Menginformasikan pembayaran uang sekolah, SPP, dan informasi terkait keuangan lainnya hanya dalam satu aplikasi.</div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Help CTA -->
        <help-cta-components></help-cta-components>
        <!-- Footer -->
        <footer-components></footer-components>

    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Footer from '@/components/Footer.vue'
    import lozad from 'lozad';

    export default {
        name: 'Sistem Informasi Scola - Rapor',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
            'footer-components': Footer
        },
        metaInfo: {
            title: 'Produk Scola - Learning Management System',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>