<template>
  <div class="about">
    <header-components activeClass="about"></header-components>
    <header-components-mobile></header-components-mobile>
    <div class="section-header-block section-header-block--2">
        <div class="section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="row justify-content-center">
                    <div class="col-md-10 text-center mb-1">
                        <div class="d-block text-sm font-600 text-primary">Mengenal Lebih Dekat</div>
                        <h1 class="text-mega font-600">Apa itu Scola?</h1>
                        <!-- <p class="text-lg">Wujudkan Sekolah Digital anda bersama Scola, aplikasi ekosistem pendidikan digital dengan solusi terlengkap dan terbaik!</p>
                        <div class="d-flex align-items-center justify-content-center pt-3">
                            <a href="#" class="btn btn-primary btn-lg mr-2">Daftar Sekarang</a>
                            <a href="#" class="btn btn-white btn-lg">Request Free Trial</a>
                        </div> -->
                    </div>
                    <div class="col-md-10">
                        <img src="/images/assets-header-image-2.png" class="w-100" alt="header-image">
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="text-xlg font-600 mb-4 text-center">Wujudkan Ekosistem Pendidikan Digital.</div>
                            </div>
                            <div class="col-md-6 mb-md-0 mb-4">
                                <div class="text-md">Scola merupakan aplikasi teknologi pendidikan. Kami hadir sejak tahun 2016, untuk membantu sekolah atau lembaga pendidikan dalam membangun dan mewujudkan ekosistem pendidikan digital, dengan menyediakan solusi yang tepat dan terbaik. Scola percaya bahwa teknologi merupakan salah satu kunci pemerataan pendidikan dan membuat pendidikan menjadi lebih maju.</div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-md">Dengan semangat inovasi, Scola terus mengembangkan produknya dengan melibatkan peran aktif dari sekolah, guru, murid, dan juga orang tua sehingga kami dapat terus memberikan solusi yang lengkap, tepat, dan terbaik untuk kemajuan digitalisasi sekolah. <span class="text-primary">#EkosistemPendidikanDigital</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section section-lg pb-0">
        <div class="container-fluid container-fluid-gap">
            <div class="d-block mb-5">
                <div class="h2 text-mega font-600">Mengapa Scola?</div>
            </div>
            <div class="row">
                <div class="col-md-4 mb-5">
                    <div class="card shadow-none h-100 m-0">
                        <div class="d-block h-100 border-bottom pb-4">
                            <div class="flex-1">
                                <img src="/images/check-blue-dark.png" width="40" class="mb-3" alt="header-image">
                                <div class="d-block text-lg font-500 mb-3">Ekosistem Pendidikan Lengkap</div>
                            </div>
                            <div><p class="m-0 text-muted-strong text-md">Produk dari Scola kami terus kembangkan untuk melengkapi ekosistem pendidikan digital dari sekolah dan lembaga pendidikan.</p></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-5">
                    <div class="card shadow-none h-100 m-0">
                        <div class="d-block h-100 border-bottom pb-4">
                            <div class="flex-1">
                                <img src="/images/check-blue-dark.png" width="40" class="mb-3" alt="check-blue-dark">
                                <div class="d-block text-lg font-500 mb-3">Mudah Digunakana Bagi Penguna</div>
                            </div>
                            <div><p class="m-0 text-muted-strong text-md">Setiap Produk yang kami kembangkan mengutamakan tampilan menarik, kemudahan,  dan kenyamanan user dalam pengunaan aplikasi.</p></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-5">
                    <div class="card shadow-none h-100 m-0">
                        <div class="d-block h-100 border-bottom pb-4">
                            <div class="flex-1">
                                <img src="/images/check-blue-dark.png" width="40" class="mb-3" alt="check-blue-dark">
                                <div class="d-block text-lg font-500 mb-3">Materi Pembelajaran Lengkap & Menarik</div>
                            </div>
                            <div><p class="m-0 text-muted-strong text-md">Kami menyedikan materi pembelajaran lengkap dan menarik bagi sekolah untuk mendukung proses pembelajaran berbasis digital.</p></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-5">
                    <div class="card shadow-none h-100 m-0">
                        <div class="d-block h-100 border-bottom pb-4">
                            <div class="flex-1">
                                <img src="/images/check-blue-dark.png" width="40" class="mb-3" alt="check-blue-dark">
                                <div class="d-block text-lg font-500 mb-3">Produk Terintegrasi dan Terpusat</div>
                            </div>
                            <div><p class="m-0 text-muted-strong text-md">Produk kami saling terintegrasi dan data terpusat sehingga memudahkan sekolah dalam kelola data, monitoring user, dan pengambilan keputusan.</p></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-5">
                    <div class="card shadow-none h-100 m-0">
                        <div class="d-block h-100 border-bottom pb-4">
                            <div class="flex-1">
                                <img src="/images/check-blue-dark.png" width="40" class="mb-3" alt="check-blue-dark">
                                <div class="d-block text-lg font-500 mb-3">Monitoring Orang Tua</div>
                            </div>
                            <div><p class="m-0 text-muted-strong text-md">Orang tua dengan mudah dapat memantau kegiatan, progres, dan hasil belajar anak di sekolah.</p></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-5">
                    <div class="card shadow-none h-100 m-0">
                        <div class="d-block h-100 border-bottom pb-4">
                            <div class="flex-1">
                                <img src="/images/check-blue-dark.png" width="40" class="mb-3" alt="check-blue-dark">
                                <div class="d-block text-lg font-500 mb-3">Tim Pendampingan Sekolah</div>
                            </div>
                            <div><p class="m-0 text-muted-strong text-md">Tim pendamping sekolah yang siap untuk mendampingi kepada sekolah sukses menggunakan Scola.</p></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-5">
                    <div class="card shadow-none h-100 m-0">
                        <div class="d-block h-100 border-bottom pb-4">
                            <div class="flex-1">
                                <img src="/images/check-blue-dark.png" width="40" class="mb-3" alt="check-blue-dark">
                                <div class="d-block text-lg font-500 mb-3">Keamanan Data Penguna</div>
                            </div>
                            <div><p class="m-0 text-muted-strong text-md">Infrastruktur yang aman, teruji, dan termonitoring menjadi bukti tanggung jawab kami dalam menghadirkan keamanan data terbaik bagi data penguna.</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section-w-bg-block section-w-bg-block-primary-dark">
        <div class="section section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        <div class="row align-items-center">
                            <div class="col-md-6 text-center mb-md-0 mb-5">
                                <img src="/images/assets-visi-image.png" class="w-100" alt="visi-misi">
                            </div>
                            <div class="col-md-6 text-center-sm">
                                <p class="font-weight-bolder text-warning text-md">Visi Kami</p>
                                <p class="text-white text-xlg mb-4">Membangun Ekosistem Pendidikan Digital Sekolah untuk Meningkatkan Kualitas Pendidikan di Indonesia.</p>
                                <div class="text-white font-600 text-md">Bersama Scola, Wujudkan Sekolah Masa Depanmu!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section section-lg">
        <div class="container-fluid container-fluid-gap">
            <div class="d-block mb-5">
                <div class="d-block mb-5">
                    <div class="h2 text-mega font-600">Penghargaan</div>
                </div>
                <div class="row text-sm">
                    <div class="col-md-3 mb-4 mb-md-0 text-md">
                        <div class="d-block mb-3"><font-awesome-icon icon="fa-solid fa-award" class="text-primary fa-2x ml-1" /></div>
                        Pemenang ke II di Bidang Usaha Teknologi Digital Wirausaha Muda Mandiri 2018 oleh Bank Mandiri
                    </div>
                    <div class="col-md-3 mb-4 mb-md-0 text-md">
                        <div class="d-block mb-3"><font-awesome-icon icon="fa-solid fa-award" class="text-primary fa-2x ml-1" /></div>
                        Startup terpilih dalam Program BIP Bekraf 2018
                    </div>
                    <div class="col-md-3 mb-4 mb-md-0 text-md">
                        <div class="d-block mb-3"><font-awesome-icon icon="fa-solid fa-award" class="text-primary fa-2x ml-1" /></div>
                        Best Pitching Startup 2017 oleh Pedals.id
                    </div>
                    <div class="col-md-3 mb-4 mb-md-0 text-md">
                        <div class="d-block mb-3"><font-awesome-icon icon="fa-solid fa-award" class="text-primary fa-2x ml-1" /></div>
                        Inovator di Program IBT 2017 yang dilaksanakan oleh Kemenristekdikti
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section-w-bg-block">
        <div class="container-fluid container-fluid-gap">
            <div class="section section-lg">
                <div class="d-block mb-5">
                    <div class="h2 text-mega font-600">Produk Terbaik Kami</div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-4">
                        <div class="card shadow-sm m-0 h-100 lift">
                            <div class="card-body h-100">
                                <div class="d-flex flex-column h-100">
                                    <div class="flex-1" style="height:80px;">
                                        <img src="/images/product-logo/Scola_Smart_Class_maincolor.svg" height="39" alt="scola-smart">
                                    </div>
                                    <div>
                                        <div class="badge badge-light mb-2 font-500"><font-awesome-icon icon="fa-solid fa-thumbs-up" class="mr-2"/>Produk Unggulan</div>
                                        <div class="d-block text-muted-strong">
                                            Solusi pembelajaran digital di sekolah.
                                        </div>
                                        <div class="d-block pt-3">
                                            <router-link to="/product-lms" class="btn btn-link p-0 h-auto text-sm text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                        </div>
                                        <div class="d-block pt-4 small text-muted">Powered by Scola</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="card shadow-sm m-0 h-100 lift">
                            <div class="card-body h-100">
                                <div class="d-flex flex-column h-100">
                                    <div class="flex-1" style="height:80px;">
                                        <img src="/images/product-logo/Scola_School_Pro_maincolor.svg" height="36" alt="scola-school">
                                    </div>
                                    <div>
                                        <div class="d-block text-muted-strong">
                                            Kelola data sekolah dengan teratur & terpusat.
                                        </div>
                                        <div class="d-block pt-3">
                                            <router-link to="/product-si" class="btn btn-link p-0 h-auto text-sm text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                        </div>
                                        <div class="d-block pt-4 small text-muted">Powered by Scola</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="card shadow-sm m-0 h-100 lift">
                            <div class="card-body h-100">
                                <div class="d-flex flex-column h-100">
                                    <div class="flex-1" style="height:80px;">
                                        <img src="/images/product-logo/Scola_Institution_maincolor.svg" height="36" alt="scola-institution">
                                    </div>
                                    <div>
                                        <div class="d-block text-muted-strong">
                                            Lihat performa sekolah Anda dengan spesifik.
                                        </div>
                                        <div class="d-block pt-3">
                                            <router-link to="/product-sil" class="btn btn-link p-0 h-auto text-sm text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                        </div>
                                        <div class="d-block pt-4 small text-muted">Powered by Scola</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="card shadow-sm m-0 h-100 lift">
                            <div class="card-body h-100">
                                <div class="d-flex flex-column h-100">
                                    <div class="flex-1" style="height:80px;">
                                        <img src="/images/product-logo/Scola_Content_maincolor.svg" height="36" alt="scola-image">
                                    </div>
                                    <div>
                                        <div class="d-block text-muted-strong">
                                            Integrasi konten untuk dukung pembelajaran digital.
                                        </div>
                                        <!-- <div class="d-block pt-3">
                                            <router-link to="/product-sil" class="btn btn-link p-0 h-auto text-sm text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                        </div> -->
                                        <div class="d-block pt-4 small text-muted">Powered by Scola</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-4">
                        <div class="card shadow-sm m-0 h-100 border">
                            <div class="card-body h-100">
                                <div class="d-flex flex-column h-100">
                                    <div class="flex-1" style="height:80px;">
                                        <img src="/images/product-logo/Scola_Apps_maincolor.svg" height="36" alt="scola-image">
                                    </div>
                                    <div>
                                        <div class="d-block text-muted-strong">
                                            Temukan aplikasi sesuai standar Scola untuk dukung pembelajaran di sekolah.
                                        </div>
                                        <div class="d-block pt-3">
                                            <router-link to="/product-sil" class="btn btn-link p-0 h-auto text-sm text-dark">Lihat Detail<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2 fa-sm"/></router-link>
                                        </div>
                                        <div class="d-block pt-4 small text-muted">Powered by Scola</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <!-- Help CTA -->
    <help-cta-components></help-cta-components>
     <!-- Footer -->
    <footer-components></footer-components>

  </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Footer from '@/components/Footer.vue'
    import lozad from 'lozad';

    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faAward } from '@fortawesome/free-solid-svg-icons';

    library.add(faAward)

    export default {
        name: 'About',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
            'footer-components': Footer
        },
        metaInfo: {
        title: 'Tentang Kami',
        titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
        const observer = lozad();
        observer.observe();
        setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>