<template>
    <div class="ujian-online">
        <header-components activeClass="ujian-online"></header-components>
        <header-components-mobile></header-components-mobile>
        <!-- Banner -->
        <div class="home-banner banner-ujian-online">
            <div class="container px-lg-5">
                <div class="row">
                    <div class="col-lg-5 col-md-12 text-center-md">
                        <div class="home-banner--text">
                            <h1 class="mb-4 font-weight-bolder">Ujian Online</h1>
                            <h2 class="mb-0 font-weight-normal lined lined-yellow">Ujian online merupakan fitur untuk
                                memudahkan guru dalam mengelola ujian dan siswa dalam mengerjakan ujian secara online.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-block bg-primary-gradient p-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3">
                        <router-link to="fitur-andalan" class="card m-0 text-dark">
                            <div class="py-3 px-2 text-center">
                                <div class="d-block font-weight-bolder text-xs">Kelas Online</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link to="ujian-online" class="card m-0 text-white bg-primary">
                            <div class="py-3 px-2 text-center">
                                <div class="d-block font-weight-bolder text-xs">Ujian Online</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link to="virtual-class" class="card m-0 text-dark">
                            <div class="py-3 px-2 text-center">
                                <div class="d-block font-weight-bolder text-xs">Kelas Interaktif</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-md-3">
                        <router-link to="manajemen-sistem" class="card m-0 text-dark">
                            <div class="py-3 px-2 text-center">
                                <div class="d-block font-weight-bolder text-xs">Sistem Manajemen</div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- Quick Link -->
        <div class="d-block py-4 bg-lighter border-bottom quick-link">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 px-lg-5">
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="d-flex link-list text-sm font-weight-bolder">
                                <router-link to="#featuresItem" @click.native="scrollFix('#featuresItem')" class="mr-5 text-primary">Layanan Fitur</router-link>
                                <router-link to="#keuntunganPengguna" @click.native="scrollFix('#keuntunganPengguna')" class="mr-5 text-primary">Keuntungan Pengguna</router-link>
                                <!-- <a href="#faq" class="text-primary">FAQ</a> -->
                                <!-- <a href="#faq" class="mr-5">FAQ</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Features -->
        <div id="featuresItem" class="section section-xl bg-white pt-5">
            <div class="container">
                <!-- <div class="row justify-content-center mb-5">
            <div class="col-md-10 text-center">
                <div class="title-lg font-weight-bolder mb-4"> Penguna Scola LMS </div>
            </div>
        </div> -->
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="section section-lg">
                            <div class="d-block">
                                <div class="feature-img-2">
                                    <img data-src="https://assets.scola.id/newwebsite/images/feature-ujian-online-new.svg" alt="Feature 2" class="w-100 lozad">
                                    <img data-src="https://assets.scola.id/newwebsite/images/feature-ujian-online-addon-1-new.svg" class="img-animate lozad">
                                    <img data-src="https://assets.scola.id/newwebsite/images/feature-ujian-online-addon-2-new.svg" class="img-animate-2 lozad">
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img alt="ujian-online-image" src="images/order.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Tipe Soal</div>
                                    <p class="text-md mb-0">Ujian memiliki beberapa tipe soal seperti pilihan
                                        ganda,isian singkat,essay dan beberapa tipe soal lainnya.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img alt="ujian-online-image" src="images/result.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Penilaian</div>
                                    <p class="text-md mb-0">Guru dapat melakukan penilaian terhadap jawaban ujian yang
                                        sudah dikerjakan oleh siswa.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img alt="ujian-online-image" src="images/setup.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Pengaturan Ujian</div>
                                    <p class="text-md mb-0">Guru dapat melakukan pengaturan ujian untuk mengatur kapan
                                        ujian akan diterbitkan kepada siswa.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img alt="ujian-online-image" src="images/report.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Report Otomatis</div>
                                    <p class="text-md mb-0">Guru dapat langsung melihat hasil dari ujian yang telah
                                        dilaksanakan oleh peserta didik.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img alt="ujian-online-image" src="images/form.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Konfirmasi Ujian</div>
                                    <p class="text-md mb-0">Halaman untuk peserta ujian dalam melakukan peninjauan
                                        kembali jawaban ujian yang telah dikerjakan oleh peserta.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img alt="ujian-online-image" src="images/research.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Monitoring Ujian</div>
                                    <p class="text-md mb-0">Guru dapat melihat status pengerjaan dari seluruh siswa yang melaksanakan ujian.</p>
                                </div>
                            </div>
                        </div>
                        <div class="section border-bottom">
                            <div class="d-flex align-items-center center-vertical-sm center-sm">
                                <div class="mr-0 mr-md-4 mb-3 mb-md-0">
                                    <img alt="ujian-online-image" src="images/loupe.svg" width="80">
                                </div>
                                <div>
                                    <div class="font-weight-bolder text-lg">Analisis Soal</div>
                                    <p class="text-md mb-0">Guru dapat mengetahui mutu setiap soal yang sudah di kelola oleh sistem.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="keuntunganPengguna" class="section section-lg bg-lighter">
            <div class="container">
                <div class="row justify-content-center mb-5">
                    <div class="col-md-10 text-center">
                        <div class="title-lg font-weight-bolder mb-4"> Keuntungan Pengguna pada Ujian Online </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 text-center px-lg-4 mb-5">
                        <div class="card shadow-sm h-100 mb-0">
                            <div class="card-body">
                                <img alt="ujian-online-image" src="images/ilust-3.png" width="160" class="d-inline-block mb-4">
                                <div class="font-weight-bolder text-md mb-2">Sekolah</div>
                                <p class="mb-3">Sekolah memiliki bank soal yang dijadikan data untuk guru dalam
                                    menyiapkan soal-soal ke siswa.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center px-lg-4 mb-5">
                        <div class="card shadow-sm h-100 mb-0">
                            <div class="card-body">
                                <img alt="ujian-online-image" src="images/ilust-4.png" width="160" class="d-inline-block mb-4">
                                <div class="font-weight-bolder text-md mb-2">Guru</div>
                                <p class="mb-3">Guru dapat dengan mudah membuat, mengelola, dan memberikan soal ujian
                                    untuk dikerjakan oleh siswa.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center px-lg-4 mb-5">
                        <div class="card shadow-sm h-100 mb-0">
                            <div class="card-body">
                                <img alt="ujian-online-image" src="images/ilust-1.png" width="160" class="d-inline-block mb-4">
                                <div class="font-weight-bolder text-md mb-2">Siswa</div>
                                <p class="mb-3">Siswa dapat dengan mudah mengerjakan ujian secara online dimanapun dan
                                    kapanpun.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center px-lg-4 mb-5">
                        <div class="card shadow-sm h-100 mb-0">
                            <div class="card-body">
                                <img alt="ujian-online-image" src="images/ilust-2.png" width="160" class="d-inline-block mb-4">
                                <div class="font-weight-bolder text-md mb-2">Orang Tua</div>
                                <p class="mb-3">Orang tua dapat melihat ujian apa saja yang harus dikerjakan oleh siswa.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- FAQ -->
        <!--div id="faq" class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 px-lg-5">
                        <h1 class="title-lg font-weight-normal mb-5">Pertanyaan yang sering diajukan</h1>
                        <div class="accordion-section mb-4">
                            <div class="accordion__list">
                                <button type="button" class="accordion__list-title">
                                    <span class="title">Apakah ada akun demo yang bisa digunakan untuk aplikasi Scola
                                        LMS?</span>
                                </button>
                                <div class="accordion__list-content">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis harum
                                                vero sed dolorem eligendi explicabo, placeat repudiandae nihil at quasi
                                                odit doloremque maiores assumenda ullam quod exercitationem, minus
                                                aperiam reprehenderit.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion__list">
                                <button type="button" class="accordion__list-title">
                                    <span class="title">Berapa banyak user yang dapat digunakan untuk webinar?</span>
                                </button>
                                <div class="accordion__list-content">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis harum
                                                vero sed dolorem eligendi explicabo, placeat repudiandae nihil at quasi
                                                odit doloremque maiores assumenda ullam quod exercitationem, minus
                                                aperiam reprehenderit.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion__list">
                                <button type="button" class="accordion__list-title">
                                    <span class="title">Bagaimana untuk bergabung dengan Scola LMS?</span>
                                </button>
                                <div class="accordion__list-content">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis harum
                                                vero sed dolorem eligendi explicabo, placeat repudiandae nihil at quasi
                                                odit doloremque maiores assumenda ullam quod exercitationem, minus
                                                aperiam reprehenderit.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div-->
        <div class="container px-lg-5">
            <div class="d-block pb-3 pt-3 border-bottom">
                <div class="d-flex justify-content-between">
                    <a href="fitur-andalan" class="pagination-link">
                        <div class="text-sm d-block mb-2"><span class="fe fe-arrow-left mr-2"></span>Sebelumnya</div>
                        <div class="d-block font-weight-bolder text-lg">KELAS ONLINE</div>
                    </a>
                    <a href="virtual-class" class="pagination-link pagination-next">
                        <div class="text-sm d-block mb-2">Selanjutnya<span class="fe fe-arrow-right ml-2"></span></div>
                        <div class="d-block font-weight-bolder text-lg">KELAS INTERAKTIF</div>
                    </a>
                </div>
            </div>
        </div>

        <cta-components></cta-components>
        <help-cta-components></help-cta-components>
        <footer-components></footer-components>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import Footer from '@/components/Footer.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Cta from '@/components/Cta.vue'
    import lozad from 'lozad'

    export default {
        name: 'ujian-online',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'footer-components': Footer,
            'help-cta-components': HelpCta,
            'cta-components': Cta
        },
        metaInfo: {
            title: 'Ujian Online',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>