<template>
    <div>
        <header-components activeClass="about"></header-components>
        <header-components-mobile></header-components-mobile>
        <div class="section-header-block section-header-block--2 section-header-block-white mb-4">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center mb-1">
                            <!-- <div class="d-block text-sm font-600 text-primary">Learning Management System</div> -->
                            <div class="d-block mb-3">
                                <img alt="school-image" src="/images/product-logo/Scola_Smart_Class_maincolor.svg" height="40">
                            </div>
                            <h1 class="text-mega font-600">Solusi Sekolah</h1>
                        </div>
                        <div class="col-md-10">
                            <img alt="school-image" src="/images/assets-img-school-2.png" class="w-100">
                        </div>
                        <div class="col-md-9 text-center">
                            <div class="text-lg d-block pt-5">Smart School menciptakan ruang pembelajaran digital untuk menghubungkan kegiatan belajar yang efektif.</div>
                            <div class="d-flex align-items-center justify-content-center pt-4">
                                  <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary btn-lg rounded-pill mr-2">Daftar Sekarang</a>
                                <a href="https://form.jotform.com/222553276834460" target="_blank" class="btn btn-white btn-lg rounded-pill font-italic">Request Free Trial</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="row">
                    <div class="col-md-4 mb-4">
                        <div class="d-block font-500 text-md">
                            <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-lg text-primary"/>
                            Kemudahan monitoring data penguna untuk membantu pengambilan keputusan.
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="d-block font-500 text-md">
                            <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-lg text-primary"/>
                            Memberikan informasi terkait proses pembelajaran, seperti jadwal kelas dan ujian.
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="d-block font-500 text-md">
                            <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-lg text-primary"/>
                            Menampilkan daftar tugas untuk memudahkan murid dalam proses pengerjaan.
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="d-block font-500 text-md">
                            <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-lg text-primary"/>
                            Berkas materi belajar dapat diunduh dan dipelajari sesuai dengan kebutuhan.
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="d-block font-500 text-md">
                            <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-lg text-primary"/>
                            Menyediakan wadah komunikasi antarpihak terkait.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-w-bg-block section-w-bg-block-primary-dark text-white">
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap py-lg-5">
                    <div class="row justify-content-center">
                        <div class="col-md-10">
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="school-image" src="/images/solusi-sekolah-icon/solusi-sekolah-icon-1.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Melibatkan Partisipasi Orang Tua</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Orang Tua dapat memberikan dorongan terbaik dengan kemudahan sistem yang mampu meningkatkan kualitas pendidikan yang efektif dan efisien.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="school-image" src="/images/solusi-sekolah-icon/solusi-sekolah-icon-2.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Pendidikan Digital Adaptif</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Smart School menciptakan ruang pembelajaran yang dapat diakses secara fleksibel sesuai kebutuhan sehingga siswa dapat mengeksplorasi lebih jauh untuk meningkatkan kemampuan diri.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="school-image" src="/images/solusi-sekolah-icon/solusi-sekolah-icon-3.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Ruang Digital untuk Guru</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Smart School menciptakan ruang pembelajaran yang dapat diakses secara fleksibel sesuai kebutuhan sehingga siswa dapat mengeksplorasi lebih jauh untuk meningkatkan kemampuan diri.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="school-image" src="/images/solusi-sekolah-icon/solusi-sekolah-icon-4.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Memudahkan Kepala Sekolah dalam Pemantauan</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Informasi terkait kejadian dan hal yang berkaitan dengan sekolah dapat dilihat secara real time update.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="school-image" src="/images/solusi-sekolah-icon/solusi-sekolah-icon-5.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Pihak Admin yang Saling Terkoneksi</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Disediakan ruang untuk admin terkait yang mampu memberikan informasi dan mempercepat pengambilan keputusan secara bijaksana.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="school-image" src="/images/solusi-sekolah-icon/solusi-sekolah-icon-6.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Memudahkan Kurikulum dalam Pemantauan</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Sarana untuk mengukur kemampuan yang berkaitan dengan tercapainya target sehingga mampu beradaptasi.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-lg">
             <div class="container-fluid container-fluid-gap">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card h-100 bg-light shadow-none">
                            <div class="card-body h-100 text-center">
                                <img alt="school-image" src="/images/assets-img-teacher-2.png" class="w-100 rounded-lg mb-4" />
                                <div class="d-block font-500 text-lg mb-3">
                                    Solusi Guru
                                </div>
                                <div class="d-block mb-5">
                                    <p class="m-0">
                                        Smart School membantu guru dalam mencapai potensi maksimal dalam mengajar sehingga menciptakan lingkup pendidikan terbaik
                                    </p>
                                </div>
                                <router-link to="/teacher-solution-lms" class="btn btn-primary rounded-pill">Lihat Solusi</router-link>
                            </div>
                        </div>
                    </div>
                     <div class="col-md-6">
                        <div class="card h-100 bg-light shadow-none">
                            <div class="card-body h-100 text-center">
                                <img alt="school-image" src="/images/assets-img-parent-2.png" class="w-100 rounded-lg mb-4" />
                                <div class="d-block font-500 text-lg mb-3">
                                    Solusi Orang Tua
                                </div>
                                <div class="d-block mb-5">
                                    <p class="m-0">
                                        Smart School memberikan kemudahan bagi orang tua dalam monitori perkembangan, aktivitas, dan jadwal belajar anak.
                                    </p>
                                </div>
                                <router-link to="/parent-solution-lms" class="btn btn-primary rounded-pill">Lihat Solusi</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Help CTA -->
        <help-cta-components></help-cta-components>
        <!-- Footer -->
        <footer-components></footer-components>
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Footer from '@/components/Footer.vue'
    import lozad from 'lozad';

    export default {
        name: 'Solusi Sekolah LMS',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
            'footer-components': Footer
        },
        metaInfo: {
            title: 'Produk Scola - Learning Management System',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>