<template>
    <div class="content">
        <header-components activeClass="content"></header-components>
        <header-components-mobile></header-components-mobile>
        <section class="section section-two-side push-top">
            <div class="container">
                <div class="block">
                    <div class="row">
                        <div class="column side"></div>
                        <div class="column middle">
                            <h2>
                                PRIVACY POLICY - Scola LMS
                            </h2>
                            <h4>
                                Last updated September 29, 2019
                            </h4><br>
                            <p>
                                Thank you for choosing to be part of our community at PT. INOVASI EDUKASI BANGSA
                                ("Company", "we", "us", or "our"). We are committed to protecting your personal
                                information and your right to privacy. If you have question or concerns about our policy,
                                or our practices with regards to you personal information, please contact us at
                                halo@scola.id.
                            </p>
                            <p>
                                When you visit our website scola.id, and use our services, you trust us with you personal
                                information. We take your privacy very seriously. In this privacy policy, we describe our privacy
                                policy. We seek to explain to you in the clearest way possible what information we collect, how
                                through if carefully, as it is important. If there are any terms in this privacy policy that you do
                                not agree with, please discontinue use of our Sites and our services.
                            </p>
                            <p>
                                This privacy policy applies to all information collected through our website (such as scola.id),
                                and/or any related services, sale, marketing or events (we refer to them collectively in this privacy
                                policy as the "<b>Services</b>").
                            </p>
                            <p>
                                <b>
                                    Please read this privacy policy carefully as it will help you make informed decisions about sharing
                                    your
                                    personal information with us.
                                </b>
                            </p>
                            <h3>
                                TABLE OF CONTENTS
                            </h3>
                            <h5><a href="#satu">1. WHAT INFORMATION DO WE COLLECT?</a></h5>
                            <h5><a href="#dua">2. HOW DO WE USE YOUR INFORMATION?</a></h5>
                            <h5><a href="#tiga">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></h5>
                            <h5><a href="#empat">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></h5>
                            <h5><a href="#lima">5. DO WE USE GOOLE MAPS?</a></h5>
                            <h5><a href="#enam">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></h5>
                            <h5><a href="#tujuh">7. HOW LONG DO WE KEEP YOUR INFORMATION?</a></h5>
                            <h5><a href="#delapan">8. HO DO WE KEEP YOUR INFORMATION SAFE?</a></h5>
                            <h5><a href="#sembilan">9. DO WE COLLECT INFORMATION FROM MINORS?</a></h5>
                            <h5><a href="#sepuluh">10. WHAT ARE YOU PRIVACY RIGHTS?</a></h5>
                            <h5><a href="#sebelas">11. CONTROLS FOR DO-NOT-TRACK FEATURES?</a></h5>
                            <h5><a href="#duabelas">12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></h5>
                            <h5><a href="#tigabelas">13. DO WE MAKE UPDATES TO THIS POLICY</a></h5>
                            <h5><a href="#empatbelas">14. HOW CAN YOU CONTACT U ABOUT THIS POLICY?</a></h5>
                            <br>
                            <h4>
                                <span id='satu'>1. WHAT INFORMATION DO WE COLLECT?</span>
                            </h4>
                            <h5>
                                Personal information you disclose to us
                            </h5>
                            <p>
                                <b>In Short:</b> We collect personal information that you provide to us such as name, address, contact
                                information, password and security data, payment information, and social media login data.
                            </p>
                            <p>
                                We collect personal information that you voluntarily provide to us when registering at the Services
                                expressing an
                                interest in obtaining information about us or our products ans services, when partivipating in
                                activities on the
                                Services (such as posting message in our online forums or entering competitions, contests or giveaways)
                                or otherwise
                                contacting us.
                            </p>
                            <p>
                                The personal information that we collect depends on teh context of your interactions with us and the
                                Services, the
                                choices you make and the products and features you use. The personal information we collect can similiar
                                contact data.
                            </p>
                            <p>
                                <b>Name and Contact Data.</b> We collect your first and last name, email address, postal address, phone
                                number, and other similiar contact data.
                            </p>
                            <p>
                                <b>Credentials.</b> We collect password, password hints, and similar security information used for
                                authentication
                                and account access.
                            </p>
                            <p>
                                <b>Social Media Login Data.</b> We provide you eith the option to register useing social media account
                                details.
                                Like yout Facebook, Twitter or other social media account. If you choose to register in this way, we
                                will
                                collect the information described in the section called "<a href="#enam">HOW DO WE HANDLE YOUR SOCIAL
                                    LOGINS</a>" bellow.
                            </p>
                            <p>
                                All personal information that your provide to us must be true, complete and accurate, and you must
                                notify us of any changes
                                to such personal information.
                            </p>

                            <h5>
                                Information automatically collected
                            </h5>
                            <p>
                                <b>In Short:</b> Some information - such as IP address and/or browser and device characteristich- is
                                collected
                                automatically when you visit our Services.
                            </p>
                            <p>
                                We automatically collect certain information when you visit, use or navigate the Services. This
                                information does
                                not reveal your specific identity (like your name or contact information) but may include device and
                                usage information,
                                such as IP address, browser and device characteristics, operating system, language preferences,
                                referring URLs, device name,
                                information about how and when you use our services and other technical Services, and for our internal
                                analytics and reporting
                                perposes.
                            </p>
                            <p>
                                Like many businesses, we also collect information through cookies and similar technologies.
                            </p>

                            <h5>
                                Information collected from other sources
                            </h5>
                            <p>
                                <b>In Short:</b> We many collect limited data form public databases, marketing partners, social media
                                platforms, and other outside sources.
                            </p>
                            <p>
                                We many obtain information about you from other source, such as public databases, joint marketing
                                partners, social media platforms (such as Facebook), as well as from other third parties. Examples of
                                the information we receive from other source include: social media profile information (your name,
                                gender, birtday, email, current city, state and county, user identification numbers for your contacts,
                                profile picture URL, and any other information that you choose to make public); marketing leads and
                                search result and links, including paid listings (such as sponsores links). We will inform you about the
                                source od information and the type of information we have collected about you whitin a reasonable period
                                after obtaining the personal data, but at the latest within one month.
                            </p>

                            <h4>
                                <span id='dua'>2. HOW DO WE USE YOUR INFORMATION?</span>
                            </h4>

                            <p>
                                <b>In Short:</b> we process your information for purpose based on legitimate business interests, the
                                fulfillment of our contact with you, compliance with our legal obligations, and/or your consent
                            </p>
                            <p>
                                We use personal information collected via our Service for a variety of business purposes described
                                below. We process your persoonal information for these purposes in reliance on our legitimate business
                                interest, in order to enter into or perform a contract with you, with your consent, and/or for
                                compliance with our legal obligations. We indicate the specific processing grounds we rely on next to
                                each purpose listed below.
                            </p>
                            <p>
                                We use the information we collect or receive:
                                <ul>
                                    <li>
                                        <b>To facilitate account creation and logon process</b> If you choose to link your account with
                                        us to a third party account *(such as your Google or Facebook account), we use the information
                                        you allowed us to collect from those third parties to facilitate account creation and logon
                                        process for the performance of the contact. See the section below headed "<a href="#enam">HOW DO WE
                                            HANDLE YOUR SOCIAL LOGINS</a>" for further information.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>To send you marketing and promotional communications</b> We and/or our third party marketing
                                        partners may use the personal information you send to us for our marketing purpose, if this in
                                        accordance with your marketing preferences. You can opt-out of our marketing emails at any time
                                        (see the "<a href="#sepuluh">WHAT ARE YOUR PRIVACY RIGHTS</a>" below).
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>To send administrative information to your</b> We may use your personal information to send
                                        you product, service and new feature information and/or information about changes to our terms,
                                        conditions, and policies.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>Request Feedback</b> We may use your information to request feedbak and to contact you about
                                        your use our Services.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>To protect our Services.</b> We may use your information as part of our efforts to keep our
                                        Services safe and secure (for example for fraud monitoring and prevention).
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>To enable user-to-user communications</b> We may use your information in order to enable
                                        user-to-user communications with each user's consent.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>To enforce our terms, conditions and policies for Business Purposes, Legal Reasons and
                                            Contractual.</b>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>To respond to legal requests and prevent harm.</b> If we receive a subpoena or other legal
                                        request, we may need to inspect the data we hold to determine how to respond.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>To manage user account.</b> We may use your information for the purpose of managing our
                                        account and keeping it in working order.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>To deliver services to the user.</b> We may use your information to provide you with the
                                        request services.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>To respond to user inquiries/offer support to users.</b> We may use your information to
                                        respond to your inquiries and solve any potential issues you might have with the use of Our
                                        Services.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>To other Bussiness Purposes.</b> We may use your information for other Business Purpose, such
                                        as data analysis, identifying usage trends, determining the affectiveness of our promotional
                                        campaigns and to evaluate and improver our Services, product, marketing and your ecperience. We
                                        may use and store this information in aggregated and anonymized form so that it is not
                                        associated eith individual end users and does not include personal information. We will not use
                                        identifiable personal information without your consent.
                                    </li>
                                </ul>
                            </p>

                            <h4>
                                <span id='tiga'>3. WILLL YOUR INFORMATION BE SHARED WITH ANYONE?</span>
                            </h4>
                            <p>
                                <b>In Short:</b> We only share information with your consent, to comply with laws, to provide you with
                                sevices, to protect rights, or to fulfill business obligations.
                            </p>
                            <p>
                                We may process or share data based on the following legal basis:
                                <ul>
                                    <li>
                                        <b>Consent:</b> We may process your data if you have given us specific consent to use your
                                        personal information in a specific purpose.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>Legitimate Interests:</b> We may process your data when it is reasonably necessary to achieve
                                        our legitimate business interests.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>Performance of a Contract:</b> Where we have entered into a contract with you, we may process
                                        you
                                        personal information to fulfill the terms of our contract.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>Legal Obligations:</b> We may disclose your information where we are legally required to do
                                        so in order to comply with applicable law, governmental requests, a judicial proceeding, court
                                        order, or legal process, such as in reponse to a court order or a subpoena (including in
                                        response to public authorities to meet national security or law enforcement requirements).
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>Vital Interest:</b> We may disclose your information where we believe it is necessary to
                                        investigate, prevent, or take action regarding potential violations of our policies, suspected
                                        fraud, situations involving potential threats to the safety of any person and illegal
                                        activities, or as evidence in litigation in which we are involved.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                More specifically, we may need to process your data or share your personal information in the following
                                situations:
                                <ul>
                                    <li>
                                        <b>Vendors, Consultants and Other Third-Party Service Providers.</b> We may share your data with
                                        third party vendors, service providers, contractors or agents who perform services for us or on
                                        our behalf and require access to such information to do that work. Example include: payment
                                        processing, data analysis, email delivery, hosting services, customer service and marketing
                                        efforts. We may allow selected third parties to use tracking technology on the Services, which
                                        will enable them to collect data about how you interact with the Services over time. This
                                        information may be used to, among other things, analyze and track data, determine the popularity
                                        of certain content and better understand online activity. Unless described in this Policy, we do
                                        not share, sell, rent or trade any of yout information with third parties for their promotional
                                        purposes.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>Business Transfers.</b> We may share or transfer your information in connection with, or
                                        during negotiations of, any merger, sale of company assets, financing, or acquisition of all or
                                        a portion of our business to another company.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>Third-Party Advertises.</b> We may use third-party advertising companies to serve ads when
                                        you visit the Services. These companies may use information about your visits to our Website(s)
                                        and other website that are contained in web cookies and other tracking technologies in order to
                                        provied advertisements about goods and services of interest to you.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>Business Partners.</b> We may share your information with our business partners to offer you
                                        certain product, services or promotions.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <b>Other Users.</b> When you share personal information (for example, by posting comments,
                                        contributions or other content to the Services) or otherwise interact with public areas of the
                                        Services, such personal information may be viewed by all users and may be publicy distributed
                                        outside the Services in perpetuity. If you interact with other users our Services and register
                                        through a social network (such as Facebook), your contacts on the social network will see your
                                        name, profile photo, and descriptions of your activity. Similary, other users will be able to
                                        view descriptions of your activity, communicate with you within our Services, and view your
                                        profile.
                                    </li>
                                </ul>
                            </p>

                            <h4>
                                <span id='empat'> 4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
                            </h4>
                            <p>
                                <b>In Short:</b> We may use cookies and other tracking technologies to collect and store your
                                information.
                            </p>
                            <p>
                                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
                                information. Specific information about how we use such technologies and how you can refuse certain
                                cookies is set out in our Cookies Policy.
                            </p>

                            <h4>
                                <span id='lima'>5. DO WE USE GOOGLE MAPS?</span>
                            </h4>
                            <p>
                                <b>In Short:</b> Yes, we use Google Maps for the purpose of providing better service.
                            </p>
                            <p>
                                This website, mobile application, or Facebook application users Google Maps APIs. You may find the
                                Google Maps APIs Terms of Service <a href="https://cloud.google.com/maps-platform/terms/?__utma=102347093.477059222.1569922335.1569922344.1569922344.1&__utmb=102347093.0.10.1569922344&__utmc=102347093&__utmx=-&__utmz=102347093.1569922344.1.1.utmcsr=google%7Cutmccn=(organic)%7Cutmcmd=organic%7Cutmctr=(not%20provided)&__utmv=-&__utmk=221408664&_ga=2.127183109.924563521.1569922335-477059222.1569922335">here</a>. To better understand Google's Privacy Policy,
                                please refer to this <a href="https://policies.google.com/privacy?hl=en">link</a>.
                            </p>
                            <p>
                                By using our Maps API Implementation, you agree to be bound by Google's Terms of Service. You agree to
                                allow us to obtain or cache your location. You may revoke your consent at anytime. We use information
                                about in conjuction with data from other data providers.
                            </p>

                            <h4>
                                <span id='enam'>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
                            </h4>
                            <p>
                                <b>In Short:</b> If you choose to register or log in to our services using a social media account, we
                                may have access to certain information about you.
                            </p>
                            <p>
                                Our Services offer you the ability to register and login using your third party social media account
                                details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain
                                profile information about you from your social media provider. The profile Information we receive may
                                vary depending on the social media provider concerned, but will often include your name, e-mail address,
                                friends list, profile picture as well as other information you choose to make public
                            </p>
                            <p>
                                We will use the information we receive only for the purpose that are described in this privacy policy or
                                that are otherwise made clear to you on the Services. Please note that we do not control, and are not
                                responsible for, other uses of you personal information by your third party social media provider. We
                                recommend that you review their privacy policy to understand how they collect, use and share your
                                personal information, add how you can set you privacy preferences on their sites and apps.
                            </p>

                            <h4>
                                <span id='tujuh'>7. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
                            </h4>
                            <p>
                                <b>In Short:</b> We keep your information for as long as necessary to fulfill the purpose outlined in
                                this privacy policy unless otherwise required by law.
                            </p>
                            <p>
                                We will only keep your personal information for as long as it is necessary for the purpoe set out in
                                this privacy policy, unless a longer retention perios is required or permitted by law (such as tax,
                                accounting or other legal requirement). No purpose in this policy will require us keeping your personal
                                informationn for longer than the period of time in which users have an account with us.
                            </p>
                            <p>
                                When we have no ongoing legitimate business need to process your personal information, we will either
                                delete or anonymize it, or, if this is not possible (for example, because your personal infromation has
                                been stored in backup archives), then we will securely store your personal information and isolate it
                                from any further processing until deletion is possible
                            </p>

                            <h4>
                                <span id='delapan'>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
                            </h4>
                            <p>
                                <b>In Short:</b> We aim to protect your personal information through a system of organizational and
                                technical security measures.
                            </p>
                            <p>
                                We have implemented appropriate technical and organizational security measures designed to protect the
                                security of any personal information we process. However, pleae alo remember that we cannot guarantee
                                that the internet itself is 100% secure. Although we will do our best to protect your personal
                                information, transmission of personal information to and from our Service is at your own risk. Your
                                should only access the services within a secure environment.
                            </p>

                            <h4>
                                <span id='sembilan'>9. DO WE COLLECT INFORMATION FROM MINORS?</span>
                            </h4>
                            <p>
                                <b>In Short:</b> We do not knowingly collect data from or market to children under 18 years of age.
                            </p>
                            <p>
                                We do not knowingly solicit data from or market to children under 18 years of age. By using the
                                Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor
                                and consent to such minor dependent's use of the Services. If we learn that personal account and take
                                reasonable measures to promptly delete such data from our records. If you become aware of any data we
                                have collected form children under age 18, please contact us at halo@scola.id
                            </p>

                            <h4>
                                <span id='sepuluh'>10. WHAT ARE YOUR PRIVACY RIGHTS?</span>
                            </h4>
                            <p>
                                <b>In Short:</b> You may review, change, or terminate your account at any time.
                            </p>
                            <p>
                                If you are resident in the European Economic Area and you believe we are unlawfully processing your
                                personal information, you also have the right to complain to your local details here: <a
                                    href="http://ec.europe.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europe.eu/justice/data-protection/bodies/authorities/index_en.htm</a>
                            </p>
                            <h5>
                                Account Information
                            </h5>
                            <p>
                                If you would at any time like to review or change the information in your account or terminate your
                                account, you can:
                                <ul>
                                    <li>
                                        Log into your account setting and update your user account
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Contact us using the contact Information provided.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Upon your request to terminate your account, we will deactivate or delete your account and information
                                from our active databases. However, some information may be retained in our files to prevent fraud,
                                troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal
                                requirements.
                            </p>
                            <p>
                                <b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by default. If you
                                prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you
                                choose
                                to remove cookies or reject cookies, this could affect certain features or services of our Services. To
                                opt-out of interst-based advertising by advertisers on our Service visit <a
                                    href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.
                            </p>
                            <p>
                                <b>Opting out of email marketing:</b> You can unsubcribe from our marketing email list at any time by
                                clicking on the unsubcribe link in the emails that we send or by contacting us using the details
                                provided below. You will then be removed from the marketing email list-however, we will still need to
                                send you service-related emails that are necessary for the administration and use of your account. To
                                otherwise opt-out, you many:
                                <ul>
                                    <li>
                                        Contact us using the contact information provided.
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Access your account settings and update preferences.
                                    </li>
                                </ul>

                            </p>

                            <h4>
                                <span id='sebelas'>11. CONTROLS FOR DO-NOT-TRACK FEATURS</span>
                            </h4>
                            <p>
                                Most web browsers and some mobile operating systems and mobile applications include a
                                Do-Not-Track("DNT") feature or setting you can activate to signal your privacy preference not to have
                                data about your online browsing activities monitored and collected. No uniform technology standard for
                                to DNT browser signals or any other mechanism that automatically communicates your choice not to inform
                                you about that practice in a revised version of this privacy policy.
                            </p>

                            <h4>
                                <span id='duabelas'>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span>
                            </h4>
                            <p>
                                <b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding
                                access to your personal information.
                            </p>
                            <p>
                                California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who
                                are California residents to request and obtain from us, once a year and free of charge, information
                                about categories of personal information (if any) we disclosed to third parties for direct marketing
                                purposes and the names and addresses of all third parties with which we shared personal information in
                                the immediately preceding calendar year. If you are a California resident and would like to make such a
                                request, please submit your request in writing to us using the contact information provided below.
                            </p>
                            <p>
                                If you are under 18 years of age, reside in California, and have a registered account with the Services,
                                you have the right to request removal of unwanted data that you publicly post on the Service. To request
                                removal of such data, please contact us using the contact information provided below, and include the
                                email address associated with yout account and a statement that youu reside in California. We will make
                                sure the data is not publicly displayed on the Services, but please be aware that data may not be
                                completely or comprehensively removed from our systems.
                            </p>

                            <h4>
                                <span id='tigabelas'>13. DO WE MAKE UPDATE TO THIS POLICY?</span>
                            </h4>
                            <p>
                                <b>In Short:</b> Yes, we will update this policy as necessary to stay compliant with relevant laws.
                            </p>
                            <p>
                                We may update this privacy policy from time to time. The updated version will be indicated by an updated
                                "Revised" date and the updated version will be effective as soon as it is accessible. If we make
                                material changes to this privacy policy, we may notify you either by prominently posting a notice of
                                such changes or by directly sending you a notification. We encourage you to review this privacy policy
                                frequently to be informed of how we are protecting your information.
                            </p>

                            <h4>
                                <span id='empatbelas'>14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span>
                            </h4>
                            <p>
                                If you have questions or comments about this policy, you may email us at halo@scola.id or by
                                post to:
                            </p>
                            <h4>
                                PT. INOVASI EDUKASI BANGSA<br>
                                Jl. Karang Tineung Indah I no. 12a<br>
                                Bandung, Jawa Barat 40162<br>
                                Indonesia
                            </h4>

                        </div>
                        <div class="column side"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import lozad from 'lozad'
    export default {
        name: 'privacy-policy',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
        },
        metaInfo: {
            title: 'Privacy Policy',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
        }
    }
</script>