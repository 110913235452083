<template>
  <div class="blog">
    <header-components activeClass="blog"></header-components>
    <header-components-mobile></header-components-mobile>
    <div class="section-header-block section-header-block--2">
        <div class="section section-lg pt-4">
            <div class="container-fluid container-fluid-gap" v-if="news_latest">
                <div class="row">
                    <div class="col-md-12">
                        <a target="_blank" :href="news_latest.link" class="blog-featured rounded-lg" :style="`background-image:url('${news_latest.thumbnail}');`">
                            <div class="blog-featured--caption">
                                <div class="d-block mb-2 text-sm">{{news_latest.date_formated}}</div>
                                <h1>{{news_latest.title}}</h1>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section section-lg">
        <div class="container-fluid container-fluid-gap">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="title-lg mb-5">Whats New?</h2>
                    <div class="row">
                        <div class="col-md-4" v-for="item in news" :key="item.link">
                            <a target="_blank" :href="item.link" class="media-block mb-md-5 mb-4">
                                <div class="media-image mb-3 rounded-lg" :style="`background-image:url('${item.thumbnail}');`"></div><!-- Masukin image di background-image -->
                                <div class="media-caption mb-2">
                                    <div class="d-block mb-2 title font-600">{{item.title}}</div>
                                    <div class="d-block text-sm mb-2">{{item.date_formated}}</div>
                                    <!-- <p class="small">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat porro fugit alias, quae velit tenetur unde nulla inventore corrupti similique.</p> -->
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-center p-3 mt-4">
                        <a href="https://medium.com/@scola-academic" target="_blank" class="btn btn-primary px-4" type="button">Telusuri lebih banyak</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Help CTA -->
    <help-cta-components></help-cta-components>
    <!-- Footer -->
    <footer-components></footer-components>
    
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  import HeaderMobile from '@/components/HeaderMobile.vue'
  import HelpCta from '@/components/HelpCta.vue'
  import Footer from '@/components/Footer.vue'
  import axios from "axios";
  import moment from "moment"
// export function getStoriesAction(){
//   return axios('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@olle.co.id');
// }
// export function link(item){
//   return item.link.replace('https://medium.com/@olle.co.id/','/artikel/').replace("?source=rss-ac3b4904d64e------2","")
// }

  export default {
    name: 'Blog',
    components: {
      'header-components': Header,
      'header-components-mobile': HeaderMobile,
      'help-cta-components': HelpCta,
      'footer-components': Footer
    },
    data() {
        return {
            news_latest:false,
            news:[]
        }
    },
    async mounted() {
        let news            = (await axios('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@scola-academic')).data
        this.news           = news.items.slice(1, news.items.length).map((item)=>{
            item.date_formated = moment.utc(item.pubDate).format('DD MMM, YYYY')
            return item
        })
        this.news_latest    = news.items.slice(0,1)[0]
        this.news_latest.date_formated    = moment.utc(this.news_latest.pubDate).format('DD MMM, YYYY')
    },
    metaInfo: {
      title: 'Blog',
      titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
    }
  }
</script>