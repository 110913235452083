<template>
    <div class="product">
        <header-components activeClass="product"></header-components>
        <header-components-mobile></header-components-mobile>
        <div class="section-header-block section-header-block--2 mb-4">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center mb-1">
                            <!-- <div class="d-block font-600 text-primary">Produk Scola</div> -->
                            <!-- <div class="d-block mb-3">
                                <img alt="product-lms-image" src="/images/product-logo/Scola_Smart_Class_maincolor.svg" height="40">
                            </div> -->
                            <div class="d-flex align-items-center justify-content-center mb-4" style="margin-left:-8px;">
                                <img alt="product-lms-image" src="/images/icon-product-1.webp" width="80">
                                <div class="d-block font-600 text-lg ml-2">SMART CLASS</div>
                            </div>
                            <h1 class="text-mega font-600">Solusi Pembelajaran Digital di Sekolah</h1>
                            <div class="d-block text-sm text-muted">Powered by Scola</div>
                        </div>
                        <div class="col-md-7 my-5">
                            <img alt="product-lms-image" src="/images/lms-img-2.webp" class="w-100">
                        </div>
                        <div class="col-md-10 text-center">
                            <div class="text-lg d-block pt-5">Solusi untuk digitalisasi proses pembelajaran dengan penerapan sistem manajemen adaptif (<span class="font-italic">Online & Offline</span>). Smart Class merancang sistem untuk kemudahan sekolah dalam mengelola proses pembelajaran, mengintegrasikan, dan menciptakan kolaborasi efektif antara guru, siswa, orang tua, dan sekolah.</div>
                            <div class="d-flex align-items-center justify-content-center pt-4">
                                  <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary btn-lg rounded-pill mr-2">Daftar Sekarang</a>
                                <a href="https://form.jotform.com/222553276834460" target="_blank" class="btn btn-white btn-lg rounded-pill font-italic">Request Free Trial</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="row mb-5">
                    <div class="col-md-3 mb-md-0 mb-4">
                        <div class="d-block font-500 text-md">
                            <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-lg text-primary"/>
                            Memudahkan pembelajaran digital antara guru dan siswa.
                        </div>
                    </div>
                    <div class="col-md-3 mb-md-0 mb-4">
                        <div class="d-block font-500 text-md">
                            <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-lg text-primary"/>
                            Menyediakan berbagai konten modul ajar interaktif.
                        </div>
                    </div>
                    <div class="col-md-3 mb-md-0 mb-4">
                        <div class="d-block font-500 text-md">
                            <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-lg text-primary"/>
                            Tipe ujian standar ANBK untuk asesmen dan evaluasi.
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="d-block font-500 text-md">
                            <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-lg text-primary"/>
                            Memudahkan alur komunikasi antar pihak terkait.
                        </div>
                    </div>
                </div>
                <div class="d-block border rounded-lg p-4 shadow-sm">
                    <div class="d-block font-600 mb-4 text-lg">Keamanan sistem dengan empat jenis sekuritas</div>
                    <div class="row">
                        <div class="col-md-3 mb-md-0 mb-4">
                            <div class="card bg-light h-100 m-0">
                                <div class="card-body h-100">
                                    <div class="d-flex h-100 align-items-center">
                                        <font-awesome-icon icon="fa-solid fa-user-shield" class="d-block mr-3 flex-shrink-0 fa-lg"/>
                                        <div class="font-600 text-md">Kata sandi akun</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-md-0 mb-4">
                            <div class="card bg-light h-100 m-0">
                                <div class="card-body h-100">
                                    <div class="d-flex h-100 align-items-center">
                                        <font-awesome-icon icon="fa-solid fa-puzzle-piece" class="d-block mr-3 flex-shrink-0 fa-lg"/>
                                        <div class="font-600 text-md">Kode captcha</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-md-0 mb-4">
                            <div class="card bg-light h-100 m-0">
                                <div class="card-body h-100">
                                    <div class="d-flex h-100 align-items-center">
                                        <font-awesome-icon icon="fa-solid fa-shield-halved" class="d-block mr-3 flex-shrink-0 fa-lg"/>
                                        <div class="font-600 text-md font-italic">Two factor authentication</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card bg-light h-100 m-0">
                                <div class="card-body h-100">
                                    <div class="d-flex h-100 align-items-center">
                                        <font-awesome-icon icon="fa-solid fa-id-card-clip" class="d-block mr-3 flex-shrink-0 fa-lg"/>
                                        <div class="font-600 text-md font-italic">Single sign in</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-block">
            <div class="section pb-5">
                <div class="container-fluid container-fluid-gap">
                    <div class="d-block text-xxxlg font-600">Fitur andalan Smart Class</div>
                </div>
            </div>
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row align-items-center">
                        <div class="col-md-6 mb-md-0 mb-4">
                            <div class="d-block pr-lg-5">
                               <div class="feature-img-1">
                                    <img data-src="https://assets.scola.id/images/feature-kelas-online.svg" alt="Feature 1" class="w-100 lozad">
                                    <img alt="Floating classroom card illustration" data-src="https://assets.scola.id/images/feature-kelas-online-addon-1.svg" class="img-animate lozad">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="d-block pl-lg-5">
                                <div class="d-block text-xlg font-500 mb-2">Kelas Online<font-awesome-icon icon="fa-solid fa-star" class="ml-3 fa-xs text-muted"/></div>
                                <p class="d-block text-md text-muted-strong">
                                    Memudahkan pembelajaran digital antara guru dan siswa dengan standar yang sesuai serta fitur yang lengkap dan menarik.
                                </p>
                                <div class="d-block mb-3">
                                    <div class="row justify-content-center">
                                        <div class="col-4">
                                        <ul class="feature-list-check">
                                            <li><span class="fe fe-check-circle mr-2"></span>Materi Belajar</li>
                                            <li><span class="fe fe-check-circle mr-2"></span>Tugas</li>
                                            <li><span class="fe fe-check-circle mr-2"></span>Kuis & Ujian</li>
                                        </ul>
                                        </div>
                                        <div class="col-4">
                                        <ul class="feature-list-check">
                                            <li><span class="fe fe-check-circle mr-2 font-italic"></span>Feedback</li>
                                            <li><span class="fe fe-check-circle mr-2 font-italic"></span>Pooling</li>
                                            <li><span class="fe fe-check-circle mr-2"></span>Remedial</li>
                                        </ul>
                                        </div>
                                        <div class="col-4">
                                        <ul class="feature-list-check">
                                            <li><span class="fe fe-check-circle mr-2"></span>Diskusi</li>
                                            <li><span class="fe fe-check-circle mr-2"></span>Analitik</li>
                                            <li><span class="fe fe-check-circle mr-2"></span>Laporan</li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row align-items-center">
                        <div class="col-md-6 order-md-1 order-2">
                            <div class="d-block pr-lg-5">
                                <div class="d-block text-xlg font-500 mb-2">Ujian <span class="font-italic">Online</span><font-awesome-icon icon="fa-solid fa-star" class="ml-3 fa-xs text-muted"/></div>
                                <p class="d-block text-md text-muted-strong">
                                    Memudahkan guru dalam mengelola ujian siswa, mulai dari persiapan hingga memperoleh hasil akhir.
                                </p>
                                <div class="d-block mb-3">
                                    <div class="row justify-content-center">
                                        <div class="col-4">
                                            <ul class="feature-list-check">
                                                <li>
                                                <span class="fe fe-check-circle mr-2"></span>
                                                <div>Tipe Soal</div>
                                                </li>
                                                <li>
                                                <span class="fe fe-check-circle mr-2"></span>
                                                <div>Penilaian</div>
                                                </li>
                                                <li>
                                                <span class="fe fe-check-circle mr-2"></span>
                                                <div>Pengaturan Ujian</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-4">
                                            <ul class="feature-list-check">
                                                <li>
                                                <span class="fe fe-check-circle mr-2"></span>
                                                <div><span class="font-italic">Report</span> Otomatis</div>
                                                </li>
                                                <li>
                                                <span class="fe fe-check-circle mr-2"></span>
                                                <div>Konfirmasi Ujian</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-4">
                            <div class="d-block pl-lg-5">
                                <div class="feature-img-2">
                                    <img data-src="https://assets.scola.id/newwebsite/images/feature-ujian-online-new.svg" alt="Feature 2" class="w-100 lozad">
                                    <img data-src="https://assets.scola.id/newwebsite/images/feature-ujian-online-addon-1-new.svg" class="img-animate lozad">
                                    <img data-src="https://assets.scola.id/newwebsite/images/feature-ujian-online-addon-2-new.svg" class="img-animate-2 lozad">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row align-items-center">
                        <div class="col-md-6 mb-md-0 mb-4">
                            <div class="d-block pr-lg-5">
                                <div class="feature-img-3">
                                    <img data-src="https://assets.scola.id/images/feature-virtual-class.jpg" class="w-100 lozad">
                                    <img data-src="https://assets.scola.id/newwebsite/images/feature-virtual-class-addon-1-new.svg" class="img-animate lozad">
                                    <img data-src="https://assets.scola.id/images/feature-virtual-class-foreground.png" class="w-100 img-foreground lozad">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="d-block pl-lg-5">
                                <div class="d-block text-xlg font-500 mb-2">Kelas Interaktif<font-awesome-icon icon="fa-solid fa-star" class="ml-3 fa-xs text-muted"/></div>
                                <p class="d-block text-md text-muted-strong">
                                    Mendukung proses pembelajaran interaktif jarak jauh.
                                </p>
                                <div class="d-block mb-3">
                                    <div class="row justify-content-center">
                                        <div class="col-4">
                                        <ul class="feature-list-check">
                                            <li>
                                            <span class="fe fe-check-circle mr-2 "></span>
                                            <div class="font-italic">Chating</div>
                                            </li>
                                            <li>
                                            <span class="fe fe-check-circle mr-2 "></span>
                                            <div class="font-italic">Webcam</div>
                                            </li>
                                            <li>
                                            <span class="fe fe-check-circle mr-2 "></span>
                                            <div class="font-italic">Share Screen</div>
                                            </li>
                                        </ul>
                                        </div>
                                        <div class="col-4">
                                        <ul class="feature-list-check">
                                            <li>
                                            <span class="fe fe-check-circle mr-2"></span>
                                            <div class="font-italic">Breakout Room</div>
                                            </li>
                                            <li>
                                            <span class="fe fe-check-circle mr-2"></span>
                                            <div>Pengaturan <span class="font-italic">Virtual Class</span></div>
                                            </li>
                                        </ul>
                                        </div>
                                        <div class="col-4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row align-items-center">
                        <div class="col-md-6 order-md-1 order-2">
                            <div class="d-block pr-lg-5">
                                <div class="d-block text-xlg font-500 mb-2">Sistem Manajemen<font-awesome-icon icon="fa-solid fa-star" class="ml-3 fa-xs text-muted"/></div>
                                <p class="d-block text-md text-muted-strong">
                                    Mengelola sistem, memonitori proses, dan memantau perkembangan aktivitas sekolah.
                                </p>
                                <div class="d-block mb-3">
                                    <div class="row justify-content-center">
                                        <div class="col-4">
                                        <ul class="feature-list-check">
                                            <li>
                                            <span class="fe fe-check-circle mr-2"></span>
                                            <div>Kelola Pengguna (Siswa, Guru, Orang Tua)</div>
                                            </li>
                                            <li>
                                            <span class="fe fe-check-circle mr-2"></span>
                                            <div>Kelola Kelas & Rombel</div>
                                            </li>
                                            <li>
                                            <span class="fe fe-check-circle mr-2"></span>
                                            <div>Kelola Rencana Pembelajaran</div>
                                            </li>
                                        </ul>
                                        </div>
                                        <div class="col-4">
                                        <ul class="feature-list-check">
                                            <li>
                                            <span class="fe fe-check-circle mr-2"></span>
                                            <div>Kelola Kurikulum</div>
                                            </li>
                                            <li>
                                            <span class="fe fe-check-circle mr-2"></span>
                                            <div>Monitoring Kontent Guru</div>
                                            </li>
                                            <li>
                                            <span class="fe fe-check-circle mr-2"></span>
                                            <div>Kelola Topik & Subtopik</div>
                                            </li>
                                        </ul>
                                        </div>
                                        <div class="col-4">
                                        <ul class="feature-list-check">
                                            <li>
                                            <span class="fe fe-check-circle mr-2"></span>
                                            <div>Laporan Pembelajaran</div>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                        <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-4">
                            <div class="d-block pl-lg-5">
                                <img data-src="https://assets.scola.id/newwebsite/images/feature-manajemen-new.svg" class="w-100 lozad">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row align-items-center">
                        <div class="col-md-6 mb-md-0 mb-4">
                            <div class="d-block pr-lg-5">
                                <div class="d-block pl-lg-5">
                                    <img alt="product-lms-image" src="/images/scola-app-dashboard-2.png" class="w-100">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="d-block pl-lg-5">
                                <div class="d-block text-xlg font-500 mb-2">Dashboard<font-awesome-icon icon="fa-solid fa-star" class="ml-3 fa-xs text-muted"/></div>
                                <p class="d-block text-md text-muted-strong">
                                    Kami sediakan data monitoring yang lengkap untuk membantu penguna dalam melihat progres dan hasil pembelajaran.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-w-bg-block section-w-bg-block-yellow mb-5">
            <div class="section pb-0">
                <div class="container-fluid container-fluid-gap">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-md-3">
                            <img alt="product-lms-image" src="/images/assets-bulb.png" class="w-100" />
                        </div>
                        <div class="col-md-8 mb-md-0 mb-4 text-center-sm">
                            <div class="d-block text-xxxlg font-600 mb-4">Solusi Smart Class</div>
                            <p class="m-0 text-lg">Kini Anda dapat menciptakan ruang kolaborasi efektif antara guru, siswa, orang tua, admin, kepala sekolah, serta pihak terkait untuk mendukung sekolah masa depan.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-10">
                            <img alt="product-lms-image" src="/images/scola-app-preview-1.png" class="w-100">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Solusi Sekolah -->
        <div class="section">
            <div class="container-fluid container-fluid-gap">
                <div class="row justify-content-center">
                    <div class="col-md-11">
                        <div class="row align-items-center">
                            <div class="col-md-6 mb-md-0 mb-3">
                                <div class="d-block pr-lg-5">
                                    <img alt="product-lms-image" src="/images/assets-img-school-2.png" class="w-100" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="d-block pl-lg-5 text-center-sm">
                                    <div class="d-block text-xxlg font-600 mb-3">Solusi Sekolah</div>
                                    <p class="d-block mb-4 text-md">Smart Class menciptakan ruang pembelajaran digital untuk menghubungkan kegiatan belajar yang efektif.</p>
                                    <div class="d-block">
                                        <router-link to="/school-solution-lms" class="btn btn-primary rounded-pill">Pelajari Lebih Lanjut<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Solusi Guru -->
        <div class="section">
            <div class="container-fluid container-fluid-gap">
                <div class="row justify-content-center">
                    <div class="col-md-11">
                        <div class="row align-items-center">
                            <div class="col-md-6 order-md-1 order-2">
                                <div class="d-block pr-lg-5 text-center-sm">
                                    <div class="d-block text-xxlg font-600 mb-3">Solusi Guru</div>
                                    <p class="d-block mb-4 text-md">Smart Class membantu guru dalam mencapai potensi maksimal dalam mengajar sehingga menciptakan lingkup pendidikan terbaik.</p>
                                    <div class="d-block">
                                        <router-link to="/teacher-solution-lms" class="btn btn-primary rounded-pill">Pelajari Lebih Lanjut<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-3">
                                <div class="d-block pl-lg-5">
                                    <img alt="product-lms-image" src="/images/assets-img-teacher.png" class="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Solusi Orang Tua -->
        <div class="section">
            <div class="container-fluid container-fluid-gap">
                <div class="row justify-content-center">
                    <div class="col-md-11">
                        <div class="row align-items-center">
                            <div class="col-md-6 mb-md-0 mb-3">
                                <div class="d-block pr-lg-5">
                                    <img alt="product-lms-image" src="/images/assets-img-parent.png" class="w-100" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="d-block pl-lg-5 text-center-sm">
                                    <div class="d-block text-xxlg font-600 mb-3">Solusi Orang Tua</div>
                                    <p class="d-block mb-4 text-md">Smart Class memberikan kemudahan bagi orang tua dalam monitori perkembangan, aktivitas, dan jadwal belajar anak.</p>
                                    <div class="d-block">
                                        <router-link to="/parent-solution-lms" class="btn btn-primary rounded-pill">Pelajari Lebih Lanjut<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-w-bg-block section-w-bg-block-primary-dark">
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row align-items-center">
                        <div class="col-md-6 mb-md-0 mb-5">
                            <div class="d-block pr-lg-5 text-center-sm text-white">
                                <div class="d-block mb-3">
                                    <img alt="product-lms-image" src="/images/product-logo/Scola_Smart_Class_white.svg" height="40">
                                </div>
                                <div class="d-block text-xxlg font-600 mb-3">Akses kelas di manapun dan kapan pun</div>
                                <p class="d-block m-0 text-md">Smart Class memiliki aplikasi pintar yang dapat diakses oleh siswa untuk mengerjakan kuis, lihat laporan, dan lain sebagainya dengan mudah.</p>
                                <!-- <div class="d-block">
                                    <router-link to="/parent-solution-lms" class="btn btn-primary rounded-pill">Lihat Semua Fitur<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></router-link>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-md-6">
                            <img alt="product-lms-image" src="/images/preview-scola-app-student.png" class="w-100">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Testimonial -->
        <div class="section-w-bg-block">
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-8 text-center">
                            <div class="d-block text-xlg font-600 mb-4">Apa Kata Pengguna</div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid container-fluid-gap">
                    <div class="school-slider mb-3 pb-md-0 pb-5">
                        <VueSlickCarousel v-bind="schoolTesti">

                            <div class="d-block p-3">
                                <div class="card bg-primary shadow-sm mb-0">
                                    <div class="d-block">
                                        <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/2174448b3cd1b8fe3c694028ac5462c093eb7613534bd45d350518867a016d3920210205071514.mp4" data-lity>
                                            <div class="video-box shadow">
                                                <img alt="product-lms-image" src="/images/banner-testimoni-scola-1.png" class="w-100 video-box-img">
                                                <span class="fe fe-play-circle m-0"></span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="card-body text-sm text-white">
                                        <span class="text-warning">Felix, S.T., M.Kom.</span><br>
                                        <span class="small" style="margin-top:-1px;display:block;">Wakil Kepala Sekolah SMAK 2 BPK</span>
                                    </div>
                                </div>
                            </div>

                            <div class="d-block p-3">
                                <div class="card bg-primary shadow-sm mb-0">
                                    <div class="d-block">
                                        <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/d734070434e480252e4d8ebcd60f17852b4e7f4c5104b053d6a1b6ee8bb3c35820210205071158.mp4" data-lity>
                                            <div class="video-box shadow">
                                                <img alt="product-lms-image" src="/images/banner-testimoni-scola-2.png" class="w-100 video-box-img">
                                                <span class="fe fe-play-circle m-0"></span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="card-body text-sm text-white">
                                        <span class="text-warning">Dr. Hj. Anne Sukmawati, KD., M.Pd.</span><br>
                                        <span class="small" style="margin-top:-1px;display:block;">Kepala Sekolah SMKN 9 Bandung</span>
                                    </div>
                                </div>
                            </div>

                            <div class="d-block p-3">
                                <div class="card bg-primary shadow-sm mb-0">
                                    <div class="d-block">
                                        <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/7_9f6fee405aa610bfb801f6874a213d547f1d6eb58e0b0aaecbcc59acfa1de54320210205074453.mp4" data-lity>
                                            <div class="video-box shadow">
                                                <img alt="product-lms-image" src="/images/banner-testimoni-scola-8.png" class="w-100 video-box-img">
                                                <span class="fe fe-play-circle m-0"></span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="card-body text-sm text-white">
                                        <span class="text-warning">Miss Nur Aini, S.Pd.</span><br>
                                        <span class="small" style="margin-top:-1px;display:block;">Kepala Sekolah SD Palm Kids Purwokerto</span>
                                    </div>
                                </div>
                            </div>

                            <div class="d-block p-3">
                                <div class="card bg-primary shadow-sm mb-0">
                                    <div class="d-block">
                                        <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/7_7bbe4eb3bbfd077e9e51c8fe36674b6a40ecfaf29446ffad459a2c2c40e11dda20210208034731.mp4" data-lity>
                                            <div class="video-box shadow">
                                                <img alt="product-lms-image" src="/images/banner-testimoni-scola-10.png" class="w-100 video-box-img">
                                                <span class="fe fe-play-circle m-0"></span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="card-body text-sm text-white">
                                        <span class="text-warning">Yostian Ekaputra</span><br>
                                        <span class="small" style="margin-top:-1px;display:block;">Yayasan IT BPK Penabur Bogor</span>
                                    </div>
                                </div>
                            </div>
                            
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
        </div>

        <!-- Help CTA -->
        <help-cta-components></help-cta-components>
        <!-- Footer -->
        <footer-components></footer-components>
        
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import VueSlickCarousel from 'vue-slick-carousel';
    import Footer from '@/components/Footer.vue'
    import lozad from 'lozad';

    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faCircleCheck, faUserShield, faPuzzlePiece, faShieldHalved, faIdCardClip, faArrowRight, faStar } from '@fortawesome/free-solid-svg-icons';

    library.add(faCircleCheck, faUserShield, faPuzzlePiece, faShieldHalved, faIdCardClip, faArrowRight, faStar )

    export default {
        name: 'Produk LMS',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
            'footer-components': Footer,
            VueSlickCarousel
        },
        data() {
            return {
                schoolTesti: {
                    // centerMode: true,
                    // centerPadding: "300px",
                    infinite: true,
                    slidesToShow: 3,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed:  3000,
                    pauseOnHover: false,
                    responsive: [
                        {
                        breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                            }
                        }
                    ]
                },
            }
        },
        metaInfo: {
            title: 'Produk Scola - Learning Management System',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>