<template>
    <div>
        <header-components activeClass="about"></header-components>
        <header-components-mobile></header-components-mobile>
        <div class="section-header-block section-header-block--2 mb-4">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center mb-1">
                            <!-- <div class="d-block text-sm font-600 text-primary">Sistem Informasi</div> -->
                            <div class="d-block mb-3">
                                <img alt="si-tendik-image" src="/images/product-logo/Scola_School_Pro_maincolor.svg" height="36">
                            </div>
                            <h1 class="text-mega font-600">Tenaga Pendidik</h1>
                        </div>
                        <div class="col-md-9 text-center">
                            <div class="text-lg d-block pt-5">Manajemen proses penyimpanan data tenaga didik.</div>
                            <div class="d-flex align-items-center justify-content-center pt-4">
                                  <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary btn-lg rounded-pill mr-2">Daftar Sekarang</a>
                                <a href="https://form.jotform.com/222553276834460" target="_blank" class="btn btn-white btn-lg rounded-pill">Request Free Trial</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-lg pt-0">
            <div class="container-fluid container-fluid-gap">
                <div class="row">
                    <div class="col-md-12">
                        <img alt="si-tendik-image" src="/images/sim-tendik.png" class="w-100">
                    </div>
                </div>
            </div>
        </div>
        <div class="section-w-bg-block section-w-bg-block-primary-dark text-white">
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap py-lg-5">
                    <div class="row justify-content-center">
                        <div class="col-md-10">
                            <div class="d-block mb-5 border-bottom pb-5">
                                <div class="d-block text-lg mb-2 font-600">Informasi Admin</div>
                                <div class="d-block">
                                    <p class="m-0 text-md">
                                       Berisi informasi pengguna, data unit kerja, dan kelola data karyawan.
                                    </p>
                                </div>
                            </div>
                            <div class="d-block">
                                <div class="d-block text-lg mb-2 font-600">Informasi Data Karyawan</div>
                                <div class="d-block">
                                    <p class="m-0 text-md">
                                        Berisi kontrak kerja dan data manajemen karyawan.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Help CTA -->
        <help-cta-components></help-cta-components>
        <!-- Footer -->
        <footer-components></footer-components>
        
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Footer from '@/components/Footer.vue'
    import lozad from 'lozad';

    export default {
        name: 'Sistem Informasi Scola - Tenaga Pendidik',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
            'footer-components': Footer
        },
        metaInfo: {
            title: 'Produk Scola - Learning Management System',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>