<template>
    <div class="product">
        <header-components activeClass="product"></header-components>
        <header-components-mobile></header-components-mobile>
        <div class="section-header-block section-header-block--2 mb-4">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center mb-1">
                            <div class="d-block text-sm font-600 text-primary">Mengenal Lebih Dekat</div>
                            <h1 class="text-mega font-600">Produk Scola</h1>
                        </div>
                        <div class="col-md-10">
                            <img alt="product-image" src="/images/assets-header-image-3.png" class="w-100">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Product -->
        <div class="d-block">
            <div class="section-xl">
                <div class="container-fluid container-fluid-gap">
                    <div class="row row-sm align-items-center">
                        <div class="col-md-6 order-md-1 order-2">
                            <div class="d-block pr-lg-5">
                                <!-- <div class="d-block font-600 text-primary mb-4 text-md">Learning Management System</div> -->
								<!-- <div class="d-block mb-4">
									<img alt="product-image" src="/images/product-logo/Scola_Smart_Class_maincolor.svg" height="40">
								</div> -->
                                <div class="d-flex align-items-center mb-4" style="margin-left:-8px;">
                                    <img alt="product-image" src="/images/icon-product-1.webp" width="80">
                                    <div class="d-block font-600 text-lg ml-2">SMART CLASS</div>
                                </div>
                                <div class="d-block text-xxlg font-600 mb-4">Solusi pembelajaran digital di sekolah.</div>
                                <p class="d-block text-md text-muted-strong mb-4">
                                    Scola merancang sistem untuk kemudahan sekolah dalam kelola proses pembelajaran, mengintegrasikan, dan menciptakan kolaborasi efektif antara guru, siswa, orang tua, dan sekolah.
                                </p>
                                <div class="d-block">
                                    <router-link to="/product-lms" class="btn btn-primary rounded-pill">Pelajari Lebih Lanjut<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-5">
                            <div class="d-block pl-lg-5">
                                <img alt="product-image" src="/images/lms-img-2.webp" class="w-100">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-xl">
                <div class="container-fluid container-fluid-gap">
                    <div class="row row-sm align-items-center">
                        <div class="col-md-6 mb-md-0 mb-5">
                            <img alt="product-image" src="/images/sil-img-2.webp" class="w-100">
                        </div>
                        <div class="col-md-6">
                            <div class="d-block pl-lg-5">
                                <!-- <div class="d-block font-600 text-primary mb-4 text-md">Sistem Informasi Sekolah</div> -->
								<!-- <div class="d-block mb-4">
									<img alt="product-image" src="/images/product-logo/Scola_School_Pro_maincolor.svg" height="37">
								</div> -->
                                <div class="d-flex align-items-center mb-4" style="margin-left:-8px;">
                                    <img alt="product-image" src="/images/icon-product-2.webp" width="80">
                                    <div class="d-block font-600 text-lg ml-2">SCHOOL PRO</div>
                                </div>
                                <div class="d-block text-xxlg font-600 mb-4">Kelola data sekolah dengan teratur & terpusat.</div>
                                <p class="d-block text-md text-muted-strong mb-4">
                                    Solusi untuk digitalisasi proses akademik, kegiatan, dan kelola semua data di sekolah yang dapat di pantau dengan baik dalam mendukung pengambilan keputusan.
                                </p>
                                <div class="d-block">
                                    <router-link to="/product-si" class="btn btn-primary rounded-pill">Pelajari Lebih Lanjut<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-xl">
                <div class="container-fluid container-fluid-gap">
                    <div class="row row-sm align-items-center">
                        <div class="col-md-6 order-md-1 order-2">
                            <div class="d-block pr-lg-5">
                                <!-- <div class="d-block font-600 text-primary mb-4 text-md">Sistem Informasi Lembaga</div> -->
								<!-- <div class="d-block mb-4">
									<img alt="product-image" src="/images/product-logo/Scola_Institution_maincolor.svg" height="37">
								</div> -->
                                <div class="d-flex align-items-center mb-4" style="margin-left:-8px;">
                                    <img alt="product-image" src="/images/icon-product-3.webp" width="80">
                                    <div class="d-block font-600 text-lg ml-2">INSTITUTION</div>
                                </div>
                                <div class="d-block text-xxlg font-600 mb-4">Lihat performa sekolah Anda dengan spesifik.</div>
                                <p class="d-block text-md text-muted-strong">
                                    Solusi untuk digitalisasi data dan monitoring perkembangan semua sekolah di bawah naungan lembaga.
                                </p>
                                <div class="d-block">
                                    <router-link to="/product-sil" class="btn btn-primary rounded-pill">Pelajari Lebih Lanjut<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-5">
                             <img alt="product-image" src="/images/si-img-2.webp" class="w-100">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Help CTA -->
        <help-cta-components></help-cta-components>
        <!-- Footer -->
        <footer-components></footer-components>
        
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Footer from '@/components/Footer.vue'
    import lozad from 'lozad';

    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faAward } from '@fortawesome/free-solid-svg-icons';

    library.add(faAward)

    export default {
        name: 'Produk',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
            'footer-components': Footer
        },
        metaInfo: {
        title: 'Produk Scola',
        titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
        const observer = lozad();
        observer.observe();
        setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>