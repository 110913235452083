<template>
    <div class="d-block">
        <!-- Header -->
        <div class="section-header-block">
            <header-components></header-components>
            <div class="section-lg pb-0">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center mb-1">
                            <div class="d-block text-sm font-600 text-primary">#adaptifduniadigital</div>
                            <h1 class="text-mega font-600">Satu aplikasi untuk semua <span class="text-primary">solusi pendidikan.</span></h1>
                            <p class="text-lg">Wujudkan Sekolah Digital anda bersama Scola, aplikasi ekosistem pendidikan digital dengan solusi terlengkap dan terbaik!</p>
                            <div class="d-flex align-items-center justify-content-center pt-3">
                                <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary btn-lg mr-2 rounded-pill">Daftar Sekarang</a>
                                <a href="https://form.jotform.com/222553276834460" target="_blank" class="btn btn-white btn-lg rounded-pill">Request Free Trial</a>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <img alt="home-image" src="/images/assets-header-image.webp" class="w-100">
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- Numbers Data -->
        <div class="section section-lg pb-3">
            <div class="container-fluid container-fluid-gap">
                <div class="row row-sm">
                    <div class="col-md-4 py-2">
                        <div class="card-body py-0">
                            <div class="text-mega font-600">80+</div>
                            <div class="text-md font-500">Sekolah menggunakan Scola di seluruh Indonesia</div>
                        </div>
                    </div>
                    <div class="col-md-4 py-2">
                        <div class="card-body py-0">
                            <div class="text-mega font-600">3000+</div>
                            <div class="text-md font-500">Guru & pengajar menggunakan Scola</div>
                        </div>
                    </div>
                    <div class="col-md-4 py-2">
                        <div class="card-body py-0">
                            <div class="text-mega font-600">25000+</div>
                            <div class="text-md font-500">Siswa menggunakan Scola dari tingkat TK hingga SMA</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Program -->
        <div class="section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="d-block mb-5">
                    <div class="h2 text-mega font-600">Program terbaik kami saat ini.</div>
                </div>
                <div class="row row-sm">
                    <div class="col-md-3">
                        <div class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Judul Program</div>
                                <div class="d-block text-sm">Deskripsi singkat program.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Judul Program</div>
                                <div class="d-block text-sm">Deskripsi singkat program.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Judul Program</div>
                                <div class="d-block text-sm">Deskripsi singkat program.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Judul Program</div>
                                <div class="d-block text-sm">Deskripsi singkat program.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Video & Testimoni -->
        <div class="section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-block h-100 pr-lg-4">
                            <div class="card-content-video-cover">
                                <div class="card-content-video-cover-inner" style="background-image:url('/images/homebanner-1.webp');"></div>
                                <div class="card-content-video-cover-caption text-white">
                                    <div class="d-block mb-4">
                                        <div class="text-xxlg d-block font-600 mb-3">Temukan semua solusi disini.</div>
                                        <div class="d-flex align-items-center">
                                            <div class="user-avatar flex-shrink-0 mr-3"></div>
                                            <div class="font-600">Video Pengenalan Scola</div>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-white rounded-pill">Putar Video<font-awesome-icon icon="fa-solid fa-circle-play" class="ml-2" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-block pl-lg-4">
                            <div class="d-block mb-5">
                                <div class="d-block text-md mb-2"><span class="text-indigo font-600">"Lorem ipsum dolor sit amet consectetur, adipisicing elit.</span> Quis minima iure molestiae voluptate optio natus vitae quia expedita vero assumenda veritatis iusto, cupiditate accusamus."</div>
                                <div class="d-flex align-items-center">
                                    <div class="user-avatar flex-shrink-0 mr-3"></div>
                                    <div class="font-600">Kelly, Kepala Sekolah SMPN 1 Bandung</div>
                                </div>
                            </div>
                            <div class="d-block mb-5">
                                <div class="d-block text-md mb-2"><span class="text-orange font-600">"Lorem ipsum dolor sit amet consectetur</span>, adipisicing elit. Quis minima iure molestiae voluptate optio natus vitae quia expedita vero assumenda veritatis iusto, cupiditate accusamus."</div>
                                <div class="d-flex align-items-center">
                                    <div class="user-avatar flex-shrink-0 mr-3"></div>
                                    <div class="font-600">Kelly, Kepala Sekolah SMPN 1 Bandung</div>
                                </div>
                            </div>
                            <div class="d-block mb-5">
                                <div class="d-block text-md mb-2"><span class="text-teal font-600">"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis minima iure molestiae</span> voluptate optio natus vitae quia expedita vero assumenda veritatis iusto, cupiditate accusamus."</div>
                                <div class="d-flex align-items-center">
                                    <div class="user-avatar flex-shrink-0 mr-3"></div>
                                    <div class="font-600">Kelly, Kepala Sekolah SMPN 1 Bandung</div>
                                </div>
                            </div>
                            <div class="d-block">
                                <a href="#" class="btn btn-white rounded-pill">Lihat semua pengguna</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Product -->
        <div class="section-w-bg-block">
            <div class="section-xl">
                <div class="container-fluid container-fluid-gap">
                    <div class="row row-sm align-items-center">
                        <div class="col-md-6">
                            <div class="d-block pr-lg-5">
                                <div class="d-block font-600 text-primary mb-4 text-md">Learning Management System</div>
                                <div class="d-block text-xxxlg font-600 mb-4">Solusi pembelajaran digital di sekolah.</div>
                                <p class="d-block text-md text-muted-strong mb-4">
                                    Scola merancang sistem untuk kemudahan sekolah dalam kelola proses pembelajaran, mengintegrasikan, dan menciptakan kolaborasi efektif antara guru, siswa, orang tua, dan sekolah.
                                </p>
                                <div class="d-block">
                                    <router-link to="/product-lms" class="btn btn-primary rounded-pill">Pelajari Lebih Lanjut<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="d-block pl-lg-5">
                                <img alt="home-image" src="/images/lms-img-1.png" class="w-100">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-xl">
                <div class="container-fluid container-fluid-gap">
                    <div class="row row-sm">
                        <div class="col-md-6">
                            <div class="span text-muted">Image goes here...</div>
                        </div>
                        <div class="col-md-6">
                            <div class="d-block pl-lg-5">
                                <div class="d-block font-600 text-primary mb-4 text-md">Sistem Informasi Sekolah</div>
                                <div class="d-block text-xxlg font-600 mb-4">Kelola data sekolah dengan teratur & terpusat.</div>
                                <p class="d-block text-md text-muted-strong mb-4">
                                    Solusi untuk digitalisasi proses akademik, kegiatan, dan kelola semua data di sekolah yang dapat di pantau dengan baik dalam mendukung pengambilan keputusan.
                                </p>
                                <div class="d-block">
                                    <router-link to="/product-si" class="btn btn-primary rounded-pill">Pelajari Lebih Lanjut<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-xl">
                <div class="container-fluid container-fluid-gap">
                    <div class="row row-sm">
                        <div class="col-md-6">
                            <div class="d-block pr-lg-5">
                                <div class="d-block font-600 text-primary mb-4 text-md">Sistem Informasi Lembaga</div>
                                <div class="d-block text-xxlg font-600 mb-4">Lihat performa sekolah Anda dengan spesifik.</div>
                                <p class="d-block text-md text-muted-strong">
                                    Solusi untuk digitalisasi data dan monitoring perkembangan semua sekolah di bawah naungan lembaga.
                                </p>
                                <div class="d-block">
                                    <a href="#" class="btn btn-primary rounded-pill">Pelajari Lebih Lanjut<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-3" /></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                             <div class="span text-muted">Image goes here...</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Content Integration -->
        <div class="section-w-bg-block section-w-bg-block-primary">
            <div class="section-lg">
                <div class="continer-fluid container-fluid-gap">
                    <div class="d-block mb-5">
                        <div class="h2 text-mega font-600 text-white">Integrasi konten untuk dukung pembelajaran digital.</div>
                    </div>
                    <div class="row row-sm">
                        <div class="col-md-6 mb-4">
                            <div class="card bg-primary-dark-2 h-100 m-0">
                                <div class="card-body p-5 h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-5 text-white">
                                                <div class="d-block font-600 text-lg mb-3">Buku Digital Interaktif</div>
                                                <div class="d-block">Dilengkapi dengan animasi dan simulasi yang membuat momen belajar menjadi lebih menyenangkan.</div>
                                            </div>
                                        </div>
                                         <div class="row row-sm">
                                            <div class="col-md-6">
                                                <div class="card bg-white m-0">
                                                    <div class="d-block p-2">
                                                        <img alt="home-image" src="/images/pesona-edu-logo.jpg" class="w-100">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-4">
                            <div class="card bg-primary-dark-2 h-100 m-0">
                                <div class="card-body p-5 h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-5 text-white">
                                                <div class="d-block font-600 text-lg mb-3">E-Book</div>
                                                <div class="d-block">Buku digital yang lengkap untuk semua tingkat dan kemudahan akses kapan pun dan di mana pun melalui berbagai perangkat.</div>
                                            </div>
                                        </div>
                                        <div class="row row-sm">
                                            <div class="col-md-6">
                                                <div class="card bg-white m-0">
                                                    <div class="d-block p-2">
                                                        <img alt="home-image" src="/images/vibook-logo.jpg" class="w-100">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-md-0 mb-4">
                            <div class="card bg-primary-dark-2 h-100 m-0">
                                <div class="card-body p-5 h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-5 text-white">
                                                <div class="d-block font-600 text-lg mb-3">Video & Animasi</div>
                                                <div class="d-block">Kumpulan video dan animasi untuk membantu siswa belajar lebih menyenangkan.</div>
                                            </div>
                                        </div>
                                        <!-- <div class="row row-sm">
                                            <div class="col-md-6">
                                                <div class="card bg-white m-0">
                                                    <div class="d-block p-2">
                                                        <img alt="home-image" src="/images/vibook-logo.jpg" class="w-100">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-md-0 mb-4">
                            <div class="card bg-primary-dark-2 h-100 m-0">
                                <div class="card-body p-5 h-100">
                                    <div class="d-flex h-100 flex-column">
                                        <div class="flex-1">
                                            <div class="d-block mb-5 text-white">
                                                <div class="d-block font-600 text-lg mb-3">International Curiculum (Cambrigde)</div>
                                                <div class="d-block">Kurikulum yang diperkenalkan oleh Cambridge Assessment International Education dengan kualifikasi Internasional yang diakui secara global.</div>
                                            </div>
                                        </div>
                                        <!-- <div class="row row-sm">
                                            <div class="col-md-6">
                                                <div class="card bg-white m-0">
                                                    <div class="d-block p-2">
                                                        <img alt="home-image" src="/images/vibook-logo.jpg" class="w-100">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- School -->
        <div class="d-block mb-5">
            <school-list-components></school-list-components>
        </div>
        <!-- CTA -->
        <cta-components></cta-components>
        <!-- Contact -->
        <help-cta-components></help-cta-components>
        <!-- Footer -->
        <footer-components></footer-components>
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import SchoolList from '@/components/SchoolList.vue'
    import Cta from '@/components/Cta.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Footer from '@/components/Footer.vue'

    import lozad from 'lozad';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faCirclePlay, faPlus, faArrowRight, faEnvelope, faUser} from '@fortawesome/free-solid-svg-icons';

    library.add(faPlus, faCirclePlay, faArrowRight, faEnvelope, faUser)

    export default {
        name: 'HomeNew',
        components: {
            'header-components': Header,
            'school-list-components': SchoolList,
            'cta-components': Cta,
            'help-cta-components': HelpCta,
            'footer-components': Footer,
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
        scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }

    }

</script>
