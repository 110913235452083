<template>

    <div>
        <header-components activeClass="solusi-scola-guru"></header-components>
        <header-components-mobile></header-components-mobile>
        <!-- Banner -->
        <div class="home-banner banner-solusi-guru">
            <div class="container px-lg-5">
                <div class="row">
                    <div class="col-lg-5 col-md-12 text-center-md">
                        <div class="home-banner--text">
                            <h1 class="mb-4 font-weight-bolder">Solusi Scola untuk Para Guru</h1>
                            <h2 class="mb-4 font-weight-normal lined lined-yellow">Pendidikan yang terbaik berasal dari guru yang terbaik. Scola membantu guru untuk mencapai potensi maksimal dalam mengajar.</h2>
                            <router-link to="#videoIntro" @click.native="scrollFix('#videoIntro')" class="btn btn-primary px-5">Mengapa Scola?</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Video Section -->
        <div id="videoIntro" class="section section-xl bg-image bg-image-clear bg-primary card-style-bottom-left card-3d-bottom" style="background-image:url('images/banner-bg-1.jpg');">
            <div class="container">
                <div class="row align-items-center">
                <div class="col-md-7 pr-md-5 mb-md-0 mb-5">
                    <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/9cf697287379df2556499b6644f1b8a743caf86e41029feb1a936cb8dbba8b9520210205072124.mp4" data-lity>
                    <div class="video-box shadow" style="background-image:url('images/banner-testimoni-scola-4.png');">
                        <span class="fe fe-play-circle m-0"></span>
                    </div>
                    </a>
                </div>
                <div class="col-md-5 text-center-sm">
                    <div class="font-weight-bolder text-xlg mb-2 mb-md-4 text-white"><b>Apa Kata Guru Tentang Scola?</b></div>
                    <p class="mb-4 lined lined-yellow text-white">"Saya banyak sekali terbantu dalam proses belajar mengajar secara daring. Aplikasi Scola juga memudahkan saya dalam menyampaikan materi pembelajaran."
                        <br>
                        <br>
                        <span class="text-warning">Erhernanda, S.Pd</span><br>
                        <span class="small" style="margin-top:-1px;display:block;">Guru SD Palm Kids Purwokerto</span>
                    </p>
                </div>
                </div>
            </div>
        </div>

        <school-list-components></school-list-components>

        <!-- Fasilitas Scola -->
        <div class="section section-xl pb-5">
            <div class="container">
                <div class="row justify-content-center mb-5">
                    <div class="col-md-10 text-center">
                        <div class="title-lg font-weight-bolder mb-5"> Memfasilitasi 3 Metode Pembelajaran Interaktif </div>
                        <div class="row">
                            <div class="col-md-4 text-center mb-md-0 mb-5">
                                <img data-src="https://assets.scola.id/images/mode-pembelajaran-1.png" width="200" class="lozad">
                                <div class="d-block text-sm mt-3">
                                    <p>Penjelasan materi menyeluruh dalam bentuk audio-visual, video animasi, dan buku pelajaran elektronik (e-book).</p>
                                </div>
                            </div>
                            <div class="col-md-4 text-center mb-md-0 mb-5">
                                <img data-src="https://assets.scola.id/images/mode-pembelajaran-2.png" width="200" class="lozad">
                                <div class="d-block text-sm mt-3">
                                    <p>Evaluasi proses pembelajaran melalui soal tes yang bervariasi seperti tes adaptif, soal pilihan ganda, dan soal uraian (essay).</p>
                                </div>
                            </div>
                            <div class="col-md-4 text-center mb-md-0 mb-5">
                                <img data-src="https://assets.scola.id/images/mode-pembelajaran-3.png" width="200" class="lozad">
                                <div class="d-block text-sm mt-3">
                                    <p>Virtual class tatap muka secara dua arah antara guru dan siswa.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section section-xl text-white card-style-top-right bg-dark pb-0-mobile" style="position:relative;">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 mb-md-0 mb-4 text-center-sm">
                        <div class="font-weight-bolder text-xlg mb-4 text-white">Berbasis Web & App</div>
                        <p class="lined lined-yellow mb-5">Scola LMS menawarkan solusi belajar digital yang dapat diakses melalui Smartphone, Tablet, dan Komputer (berbasis web & app) oleh para Siswa, Guru, dan Orang Tua.</p>
                        <a href="register" class="btn btn-primary btn-primary-3d btn-push px-4">Daftar Sekarang</a>
                    </div>
                </div>
            </div>
            <img data-src="https://assets.scola.id/images/web-app-base.png" class="web-app-img lozad">
        </div>
        <div class="d-block bg-gradient-2 card-style-bottom-left" style="height: 12px;"></div>

        <div class="section section-lg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="row align-items-center">
                            <div class="col-md-6 mb-md-5 mb-5 order-md-last text-center">
                                <img alt="si-solusi-image" src="https://assets.scola.id/images/class-scola-4.png" width="320">
                            </div>
                            <div class="col-md-6 pr-md-4 mb-5 text-center-sm">
                                <div class="title-md mb-3 font-weight-bolder">Dukungan dan Pengawasan yang Terintegrasi dengan Cepat dan Membangun</div>
                                <p>Dunia pendidikan baru yang Scola ciptakan adalah bentuk wujud dukungan Scola terhadap pendidikan yang lebih maju. Sistem yang terintegrasi, cepat dan membangun menjadikan Scola sebagai acuan untuk dunia pendidikan masa depan.</p>
                                <router-link to="register" class="text-sm">Daftar sekarang! <span class="underline underline-2">Gratis masa coba selama 30 hari</span> <span class="fe fe-arrow-right mr-2"></span></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section section-lg">
            <div class="container-fluid container-fluid-mobile">
                <div class="row justify-content-center mb-4">
                    <div class="col-md-8 text-center">
                        <div class="title-lg font-weight-bolder mb-4"> Cerita guru tentang Scola LMS </div>
                    </div>
                </div>

                <div class="school-slider mb-5">
                    <VueSlickCarousel v-bind="schoolTesti">

                        <div class="d-block p-3 pb-0">
                            <div class="card bg-primary shadow-sm mb-0">
                                <div class="d-block">
                                    <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/7_d2d84120330f09383ce7feefa04ebd011424a2e24fd0b760101542a736b9ceb520210205071626.mp4" data-lity>
                                        <div class="video-box shadow">
                                            <img alt="si-solusi-image" src="/images/banner-testimoni-scola-6.png" class="w-100 video-box-img">
                                            <span class="fe fe-play-circle m-0"></span>
                                        </div>
                                    </a>
                                </div>
                                <div class="card-body text-sm text-white">
                                    <span class="text-warning">Siti Nurjanah, S.Kom</span><br>
                                    <span class="small" style="margin-top:-1px;display:block;">Guru SD Palm Kids Purwokerto</span>
                                </div>
                            </div>
                        </div>

                        <div class="d-block p-3 pb-0">
                            <div class="card bg-primary shadow-sm mb-0">
                                <div class="d-block">
                                    <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/7_43591e7d068e403ab68cb7b198383398323cc2448e79f0da4f7455fea476bf9120210205071704.mp4" data-lity>
                                        <div class="video-box shadow">
                                            <img alt="si-solusi-image" src="/images/banner-testimoni-scola-7.png" class="w-100 video-box-img">
                                            <span class="fe fe-play-circle m-0"></span>
                                        </div>
                                    </a>
                                </div>
                                <div class="card-body text-sm text-white">
                                    <span class="text-warning">Lis Arofah Rismawati</span><br>
                                    <span class="small" style="margin-top:-1px;display:block;">Guru SD Palm Kids Purwokerto</span>
                                </div>
                            </div>
                        </div>

                        <div class="d-block p-3 pb-0">
                            <div class="card bg-primary shadow-sm mb-0">
                                <div class="d-block">
                                    <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/7_43591e7d068e403ab68cb7b198383398323cc2448e79f0da4f7455fea476bf9120210205071704.mp4" data-lity>
                                        <div class="video-box shadow">
                                            <img alt="si-solusi-image" src="/images/banner-testimoni-scola-4.png" class="w-100 video-box-img">
                                            <span class="fe fe-play-circle m-0"></span>
                                        </div>
                                    </a>
                                </div>
                                <div class="card-body text-sm text-white">
                                    <span class="text-warning">Erhernanda, S.Pd</span><br>
                                    <span class="small" style="margin-top:-1px;display:block;">Guru SD Palm Kids Purwokerto</span>
                                </div>
                            </div>
                        </div>

                        <div class="d-block p-3 pb-0">
                            <div class="card bg-primary shadow-sm mb-0">
                                <div class="d-block">
                                    <a href="https://scola.s3.ap-southeast-1.amazonaws.com/bank_materi/video/7_62befb7b41adae1ecff9d000f38d8832e99b2a410209eee7bd895918abbc81f720210208034823.mp4" data-lity>
                                        <div class="video-box shadow">
                                            <img alt="si-solusi-image" src="/images/banner-testimoni-scola-9.png" class="w-100 video-box-img">
                                            <span class="fe fe-play-circle m-0"></span>
                                        </div>
                                    </a>
                                </div>
                                <div class="card-body text-sm text-white">
                                    <span class="text-warning">Ignatius Wiradinata, S.S.</span><br>
                                    <span class="small" style="margin-top:-1px;display:block;">Pengajar ICT SD Nasional Plus BPK Penabur Bogor</span>
                                </div>
                            </div>
                        </div>

                    </VueSlickCarousel>
                </div>    
            </div>
        </div>

        <cta-components></cta-components>
        <help-cta-components></help-cta-components>
        <footer-components></footer-components>
    </div>

</template>

<script>
  import Header from '@/components/Header.vue'
  import HeaderMobile from '@/components/HeaderMobile.vue'
  import Footer from '@/components/Footer.vue'
  import SchoolList from '@/components/SchoolList.vue'
  import HelpCta from '@/components/HelpCta.vue'
  import Cta from '@/components/Cta.vue'
  import lozad from 'lozad'
  import VueSlickCarousel from 'vue-slick-carousel';

  export default {
    name: 'solusi-scola-guru',
    components: {
      'header-components': Header,
      'header-components-mobile': HeaderMobile,
      'footer-components': Footer,
      'school-list-components': SchoolList,
      'help-cta-components': HelpCta,
      'cta-components': Cta,
      VueSlickCarousel
    },
    metaInfo: {
      title: 'Solusi Scola untuk Guru',
      titleTemplate: 'Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
    },
    data() {
        return {
            schoolTesti: {
                // centerMode: true,
                // centerPadding: "300px",
                infinite: true,
                slidesToShow: 3,
                dots: true,
                autoplay: true,
                autoplaySpeed:  3000,
                responsive: [
                    {
                    breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                        }
                    }
                ]
            },
        }
    },
    mounted() {
      const observer = lozad();
      observer.observe();
      setTimeout(() => this.scrollFix(this.$route.hash), 1);
    },
    methods: {
        scrollFix: function(hashbang)
        {
            location.hash = hashbang;

            // remove fragment as much as it can go without adding an entry in browser history:
            window.location.replace("#");

            // slice off the remaining '#' in HTML5:    
            if (typeof window.history.replaceState == 'function') {
                history.replaceState({}, '', window.location.href.slice(0, -1));
            }

        }
    }
  }
</script>