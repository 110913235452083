<template>
    <div>
        <header-components activeClass="about"></header-components>
        <header-components-mobile></header-components-mobile>
        <div class="section-header-block section-header-block--2 mb-4">
            
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-4">
                            <div class="card-content-group">
                                <div class="card-content-img">
                                    <div class="card-content-img-inner" style="background-image:url('/images/program-sekolah-hebat-banner-1.png');"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="d-block pl-lg-5">
                                <div class="d-block">
                                    <div class="d-block text-sm font-600 text-primary">Program Terbaik Kami</div>
                                    <h1 class="text-mega font-600">Program Sekolah Hebat</h1>
                                </div>
                                <div class="text-lg d-block">Program Sekolah Hebat merupakan program kolaborasi antara Scola dengan PesonaEdu untuk menghadirkan ekosistem pendidikan digital lengkap dengan buku interaktif.</div>
                                <div class="row row-sm pt-4">
                                    <div class="col-3">
                                        <div class="card bg-white m-0">
                                            <div class="d-block p-2">
                                                <img src="/images/scola-logo-boxed.png" class="w-100">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="card bg-white m-0">
                                            <div class="d-block p-2">
                                                <img src="/images/pesona-edu-logo.jpg" class="w-100">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center pt-4">
                                      <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary btn-lg rounded-pill mr-2">Daftar Sekarang</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Program -->
        <div class="section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="d-block mb-5">
                    <div class="h2 text-mega font-600">Program terbaik kami saat ini.</div>
                </div>
                <div class="row row-sm">
                    <div class="col-md-3 col-6 mb-md-4 mb-5">
                        <div class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/program-sekolah-digital-banner-2.png');"></div>
                            </div>
                            <div class="card-content-caption mb-3">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Sekolah Digital</div>
                                <div class="d-block text-sm">Program dari Scola khusus untuk sekolah-sekolah yang minat bergabung selama program GESS Event.</div>
                            </div>
                            <router-link to="/program-sekolah-digital" class="btn btn-white rounded-pill">Lihat Program</router-link>
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-md-4 mb-5">
                        <div class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/program-sekolah-mitra-banner-3.png');"></div>
                            </div>
                            <div class="card-content-caption mb-3">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Sekolah Mitra</div>
                                <div class="d-block text-sm">Program dari Scola khusus untuk sekolah-sekolah yang minat bergabung selama program GESS Event.</div>
                            </div>
                            <router-link to="/program-sekolah-mitra" class="btn btn-white rounded-pill">Lihat Program</router-link>
                        </div>
                    </div>
                    <div class="col-md-3 col-6 mb-md-4 mb-5">
                        <div class="card-content-group">
                            <div class="card-content-img">
                                <div class="card-content-img-inner" style="background-image:url('/images/program-akan-datang-banner.png');"></div>
                            </div>
                            <div class="card-content-caption">
                                <div class="d-block font-600 text-md block-ellipsis mb-2">Program Akan Datang</div>
                                <div class="d-block text-sm">Kami selalu memberikan program-program terbaik untuk dukung ekosistem pendidikan di sekolah anda!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <help-cta-components></help-cta-components>
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import lozad from 'lozad';

    export default {
        name: 'Program Sekolah Hebat',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
        },
        metaInfo: {
            title: 'Produk Scola - Learning Management System',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>