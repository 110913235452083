<template>
    <div>
        <header-components activeClass="about"></header-components>
        <header-components-mobile></header-components-mobile>
        <div class="section-header-block section-header-block--2 section-header-block-white mb-4">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center mb-1">
                            <div class="d-block text-sm font-600 text-primary">Learning Management System</div>
                            <h1 class="text-mega font-600">Solusi Guru</h1>
                        </div>
                        <div class="col-md-10">
                            <img alt="teacher-solution-image" src="/images/assets-img-teacher.png" class="w-100">
                        </div>
                        <div class="col-md-9 text-center">
                            <div class="text-lg d-block pt-5">Scola membantu guru dalam mencapai potensi maksimal dalam mengajar sehingga menciptakan lingkup pendidikan terbaik.</div>
                            <div class="d-flex align-items-center justify-content-center pt-4">
                                  <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary btn-lg rounded-pill mr-2">Daftar Sekarang</a>
                                <a href="https://form.jotform.com/222553276834460" target="_blank" class="btn btn-white btn-lg rounded-pill font-italic">Request Free Trial</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-w-bg-block section-w-bg-block-primary-dark text-white">
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap py-lg-5">
                    <div class="row justify-content-center">
                        <div class="col-md-10">
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="teacher-solution-image" src="/images/solusi-guru-icon/solusi-guru-icon-1.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Memaksimalkan Potensi Guru</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                        Membantu guru dalam mencapai potensi maksimal dalam mengajar.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="teacher-solution-image" src="/images/solusi-guru-icon/solusi-guru-icon-2.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Data Repository yang Lengkap</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Mengelola dokumen administrasi arsip digital pada sekolah.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="teacher-solution-image" src="/images/solusi-guru-icon/solusi-guru-icon-3.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Solusi untuk Tenaga Didik</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Manajemen proses penyimpanan data tenaga didik.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="teacher-solution-image" src="/images/solusi-guru-icon/solusi-guru-icon-2.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Ruang Publikasi Jurnal </div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                        Kumpulan jurnal pendidikan yang dapat digunakan sesuai kebutuhan.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="teacher-solution-image" src="/images/solusi-guru-icon/solusi-guru-icon-4.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Fitur Presensi yang Lengkap </div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Presensi yang dilengkapi dengan fitur penambahan surat izin tidak hadir.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="teacher-solution-image" src="/images/solusi-guru-icon/solusi-guru-icon-5.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Penilaian</div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Memudahkan rekapitulasi nilai tugas, ujian, dan sikap.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-5 border-bottom pb-5">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="teacher-solution-image" src="/images/solusi-guru-icon/solusi-guru-icon-6.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Memudahkan Alur Komunikasi </div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Menyediakan wadah komunikasi antara orang tua dan murid.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 mr-4">
                                    <img alt="teacher-solution-image" src="/images/solusi-guru-icon/solusi-guru-icon-7.svg" width="80">
                                </div>
                                <div class="d-block">
                                    <div class="d-block text-lg mb-2 font-600">Modul Pembelajaran Interaktif </div>
                                    <div class="d-block">
                                        <p class="m-0 text-md">
                                            Bahan materi belajar berupa dokumen, gambar, video, dan forum diskusi.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-lg">
             <div class="container-fluid container-fluid-gap">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card h-100 bg-light shadow-none">
                            <div class="card-body h-100 text-center">
                                <img alt="teacher-solution-image" src="/images/assets-img-school-2.png" class="w-100 rounded-lg mb-4" />
                                <div class="d-block font-500 text-lg mb-3">
                                    Solusi Sekolah
                                </div>
                                <div class="d-block mb-5">
                                    <p class="m-0">
                                        Scola menciptakan ruang pembelajaran digital untuk menghubungkan kegiatan belajar yang efektif.
                                    </p>
                                </div>
                                <router-link to="/school-solution-lms" class="btn btn-primary rounded-pill">Lihat Solusi</router-link>
                            </div>
                        </div>
                    </div>
                     <div class="col-md-6">
                        <div class="card h-100 bg-light shadow-none">
                            <div class="card-body h-100 text-center">
                                <img alt="teacher-solution-image" src="/images/assets-img-parent-2.png" class="w-100 rounded-lg mb-4" />
                                <div class="d-block font-500 text-lg mb-3">
                                    Solusi Orang Tua
                                </div>
                                <div class="d-block mb-5">
                                    <p class="m-0">
                                        Scola memberikan kemudahan bagi orang tua dalam monitori perkembangan, aktivitas, dan jadwal belajar anak.
                                    </p>
                                </div>
                                <router-link to="/parent-solution-lms" class="btn btn-primary rounded-pill">Lihat Solusi</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Help CTA -->
        <help-cta-components></help-cta-components>
        <!-- Footer -->
        <footer-components></footer-components>
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Footer from '@/components/Footer.vue'
    import lozad from 'lozad';

    export default {
        name: 'Solusi Guru LMS',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
            'footer-components': Footer
        },
        metaInfo: {
            title: 'Produk Scola - Learning Management System',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>