<template>
    <div>
        <header-components activeClass="about"></header-components>
        <header-components-mobile></header-components-mobile>
        <div class="section-header-block section-header-block--2 mb-4">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center mb-1">
                            <!-- <div class="d-block text-sm font-600 text-primary">Sistem Informasi</div> -->
                            <div class="d-block mb-3">
                                <img alt="si-tatausaha-image" src="/images/product-logo/Scola_School_Pro_maincolor.svg" height="36">
                            </div>
                            <h1 class="text-mega font-600">Tata Usaha</h1>
                        </div>
                        <div class="col-md-9 text-center">
                            <div class="text-lg d-block pt-5">Solusi untuk menyusun seluruh informasi sekolah agar terangkum hanya dalam satu aplikasi sehingga memudahkan pencarian.</div>
                            <div class="d-flex align-items-center justify-content-center pt-4">
                                  <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary btn-lg rounded-pill mr-2">Daftar Sekarang</a>
                                <a href="https://form.jotform.com/222553276834460" target="_blank" class="btn btn-white btn-lg rounded-pill">Request Free Trial</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="d-block border-bottom pb-4 mb-4">
                    <div class="row align-items-center">
                        <div class="col-md-2">
                            <div class="card-content-group">
                                <div class="card-content-img">
                                    <div class="card-content-img-inner" style="background-image:url('/images/school-pro-repository.png');"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="d-block text-xlg font-600">Repositori</div>
                            <div class="d-block text-md">Mengelola dokumen administrasi arsip digital pada sekolah.</div>
                        </div>
                    </div>
                </div>
                <div class="d-block border-bottom pb-4 mb-4">
                    <div class="row align-items-center">
                        <div class="col-md-2">
                            <div class="card-content-group">
                                <div class="card-content-img">
                                    <div class="card-content-img-inner" style="background-image:url('/images/school-pro-sarpras.png');"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="d-block text-xlg font-600">Sarana & Prasarana</div>
                            <div class="d-block text-md">Sistem informasi untuk mengelola & mencatat sarana prasarana.</div>
                        </div>
                    </div>
                </div>
                 <div class="d-block border-bottom pb-4 mb-4">
                    <div class="row align-items-center">
                        <div class="col-md-2">
                            <div class="card-content-group">
                                <div class="card-content-img">
                                    <div class="card-content-img-inner" style="background-image:url('/images/school-pro-tendik.png');"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-10">
                            <div class="d-block text-xlg font-600">Tenaga Pendidik</div>
                            <div class="d-block text-md">Manajemen proses penyimpanan data tenaga didik.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <help-cta-components></help-cta-components>
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import lozad from 'lozad';

    export default {
        name: 'Sistem Informasi Scola - Tata Usaha',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
        },
        metaInfo: {
            title: 'Produk Scola - Learning Management System',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>