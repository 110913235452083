<template>
    <div>
        <header-components activeClass="about"></header-components>
        <header-components-mobile></header-components-mobile>
        <div class="section-header-block section-header-block--2 mb-4">
            <div class="section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center mb-1">
                            <!-- <div class="d-block font-600 text-primary">Produk Scola</div> -->
                            <!-- <div class="d-block mb-3">
                                <img alt="si-product-image" src="/images/product-logo/Scola_Institution_maincolor.svg" height="40">
                            </div> -->
                            <div class="d-flex align-items-center justify-content-center mb-4" style="margin-left:-8px;">
                                <img alt="si-product-image" src="/images/icon-product-3.webp" width="80">
                                <div class="d-block font-600 text-lg ml-2">INSTITUTION</div>
                            </div>
                            <h1 class="text-mega font-600">Lihat performa sekolah Anda dengan spesifik.</h1>
                            <div class="d-block text-sm text-muted">Powered by Scola</div>
                        </div>
                        <div class="col-md-7 py-5">
                            <img alt="si-product-image" src="/images/si-img-2.webp" class="w-100">
                        </div>
                        <div class="col-md-10 text-center">
                            <div class="text-lg d-block pt-5">Solusi untuk digitalisasi data dan monitoring perkembangan semua sekolah di bawah naungan lembaga. </div>
                            <div class="d-flex align-items-center justify-content-center pt-4">
                                  <a href="https://form.jotform.com/222552676470459" target="_blank" class="btn btn-primary btn-lg rounded-pill mr-2">Daftar Sekarang</a>
                                <a href="https://form.jotform.com/222553276834460" target="_blank" class="btn btn-white btn-lg rounded-pill font-italic">Request Free Trial</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section section-lg">
            <div class="container-fluid container-fluid-gap">
                <div class="row">
                    <div class="col-md-4">
                        <div class="d-block text-mega text-primary">01</div>
                        <div class="d-block font-500 text-lg">Pengambilan keputusan dalam suatu diskusi secara efektif dan efisien.</div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-block text-mega text-primary">02</div>
                        <div class="d-block font-500 text-lg">Memantau perkembangan sekolah secara statistik.</div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-block text-mega text-primary">03</div>
                        <div class="d-block font-500 text-lg">Memperoleh informasi data siswa dan guru.</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-w-bg-block section-w-bg-block-primary-dark">
            <div class="section section-lg">
                <div class="container-fluid container-fluid-gap">
                    <div class="d-block mb-5 pb-5 text-white">
                        <div class="h2 text-xxlg font-600">Mengapa Scola Institution?</div>
                        <div class="d-block">
                            <p class="m-0 text-lg">Dengan sistem manajemen terstruktur, Scola Institution memudahkan pengelolaan sistem sekolah dengan produk-produk terbaik.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-5">
                            <div class="card shadow-none bg-transparent text-white h-100 m-0">
                                <div class="d-flex flex-column h-100 border-bottom pb-4">
                                    <div class="flex-1">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-2x text-primary"/>
                                        <div class="d-block text-lg font-500">Produk saling terintegrasi.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-5">
                            <div class="card shadow-none bg-transparent text-white h-100 m-0">
                                <div class="d-flex flex-column h-100 border-bottom pb-4">
                                    <div class="flex-1">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-2x text-primary"/>
                                        <div class="d-block text-lg font-500">Kemudahan kelola user pengguna.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-5">
                            <div class="card shadow-none bg-transparent text-white h-100 m-0">
                                <div class="d-flex flex-column h-100 border-bottom pb-4">
                                    <div class="flex-1">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-2x text-primary"/>
                                        <div class="d-block text-lg font-500">Kemudahan dalam monitoring dan pengawasan.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-5">
                            <div class="card shadow-none bg-transparent text-white h-100 m-0">
                                <div class="d-flex flex-column h-100 border-bottom pb-4">
                                    <div class="flex-1">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-2x text-primary"/>
                                        <div class="d-block text-lg font-500">Kumpulan data terpusat lingkup sekolah.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-5">
                            <div class="card shadow-none bg-transparent text-white h-100 m-0">
                                <div class="d-flex flex-column h-100 border-bottom pb-4">
                                    <div class="flex-1">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" class="d-block mb-3 fa-2x text-primary"/>
                                        <div class="d-block text-lg font-500">Kemudahan fitur melaporkan kejadian yang terjadi sehingga mempercepat pengambilan keputusan.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-5">
                            <img alt="si-product-image" src="/images/assets-doughnut-chart.png" class="w-100">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Help CTA -->
        <help-cta-components></help-cta-components>
        <!-- Footer -->
        <footer-components></footer-components>
        
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import HeaderMobile from '@/components/HeaderMobile.vue'
    import HelpCta from '@/components/HelpCta.vue'
    import Footer from '@/components/Footer.vue'
    import lozad from 'lozad';

    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faCircleCheck, faUserShield, faPuzzlePiece, faShieldHalved, faIdCardClip, faArrowRight } from '@fortawesome/free-solid-svg-icons';

    library.add(faCircleCheck, faUserShield, faPuzzlePiece, faShieldHalved, faIdCardClip, faArrowRight )

    export default {
        name: 'Produk SIL',
        components: {
            'header-components': Header,
            'header-components-mobile': HeaderMobile,
            'help-cta-components': HelpCta,
            'footer-components': Footer
        },
        metaInfo: {
            title: 'Produk Scola - Learning Management System',
            titleTemplate: '%s - Scola LMS | Tingkatkan Pembelajaran Di Sekolah Anda'
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        methods: {
            scrollFix: function(hashbang)
            {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>